import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CloseCircleOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, Space, Typography } from "antd";
import styled from "styled-components";

import { ContentCard, ContentWrapperNarrow } from "src/components/layout/LayoutComponents";
import { RootState } from "src/store/reducers/rootReducer";
import { ErrorResponse } from "src/utils/Axios";

export const CloseCircleIcon = styled(CloseCircleOutlined)`
	color: var(--teylor-light-gray);
	font-size: 112px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

const Divider = styled(AntdDivider)`
	margin: 32px 0;
`;

const ContactItem = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 8px;
	align-items: center;
`;

const { Title, Text, Link } = Typography;

interface Props {
	error?: ErrorResponse | Error;
}

const Error = ({ error }: Props) => {
	const { t } = useTranslation();
	const partnerState = useSelector((state: RootState) => state.partnerState);
	const isPlainError =
		error && (error as unknown as { prototype: unknown })?.prototype && error instanceof Error;

	return (
		<ContentWrapperNarrow>
			<ContentCard style={{ alignItems: "center" }}>
				<CloseCircleIcon />
				<Title level={3} style={{ textAlign: "center" }}>
					{t("common.errors.title")}
				</Title>
				{!isPlainError && (
					<Text strong type="secondary" style={{ textAlign: "center" }}>
						{t("common.errors.error_code", {
							errorCode: (error as ErrorResponse)?.response?.status,
						})}
					</Text>
				)}
				<Divider />
				<Space direction="vertical">
					<Text type="secondary">{t("common.errors.subtitle_1")}</Text>
					<Text type="secondary">{t("common.errors.subtitle_2")}</Text>
					<Text type="secondary">
						<ContactItem>
							<PhoneOutlined />
							<Text type="secondary">{partnerState.customer_support_phone}</Text>
						</ContactItem>
						<ContactItem>
							<MailOutlined />
							<Link href={`mailto:${partnerState.customer_support_email}`}>
								{partnerState.customer_support_email}
							</Link>
						</ContactItem>
					</Text>
				</Space>
			</ContentCard>
		</ContentWrapperNarrow>
	);
};

export default Error;
