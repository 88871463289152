import { PresetStatusColorType } from "antd/lib/_util/colors";

import { ApplicationStatuses } from "@teylor-tools/Api";

export enum Status {
	IN_REVIEW = "in_review",
	IN_REVIEW_AFTER_SIGNATURE = "in_review_after_signature",
	CLOSED = "closed",
	PAID_OUT = "paid_out",
	NOT_COMPLETED = "not_completed",
	WAITING_FOR_SIGNATURES = "waiting_for_signatures",
	UNKNOWN = "unknown",
}

export const getStatus = (status: ApplicationStatuses): Status => {
	switch (status) {
		case ApplicationStatuses.InternalReview:
		case ApplicationStatuses.InternalPricing:
		case ApplicationStatuses.ExternalPricing:
		case ApplicationStatuses.Submitted:
		case ApplicationStatuses.PreCheck:
		case ApplicationStatuses.BankSubmitted:
		case ApplicationStatuses.OfferAvailable:
		case ApplicationStatuses.OfferAccepted:
		case ApplicationStatuses.Pricing:
		case ApplicationStatuses.SalesPreCheck:
			return Status.IN_REVIEW;
		case ApplicationStatuses.AwaitingPayout:
		case ApplicationStatuses.FinalReview:
		case ApplicationStatuses.Approved:
			return Status.IN_REVIEW_AFTER_SIGNATURE;
		case ApplicationStatuses.Rejected:
			return Status.CLOSED;
		case ApplicationStatuses.PaidOut:
			return Status.PAID_OUT;
		case ApplicationStatuses.Open:
			return Status.NOT_COMPLETED;
		case ApplicationStatuses.Signature:
			return Status.WAITING_FOR_SIGNATURES;
		default:
			return Status.UNKNOWN;
	}
};

type Colors = {
	[key in Status]: PresetStatusColorType;
};

export const TAG_COLORS: Colors = {
	[Status.CLOSED]: "default",
	[Status.NOT_COMPLETED]: "error",
	[Status.WAITING_FOR_SIGNATURES]: "error",
	[Status.IN_REVIEW]: "processing",
	[Status.IN_REVIEW_AFTER_SIGNATURE]: "processing",
	[Status.PAID_OUT]: "success",
	[Status.UNKNOWN]: "default",
};
