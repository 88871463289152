import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Spin, Typography } from "antd";
import styled from "styled-components";

import { RoutePaths } from "src/Routes";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

import { Button, CheckCircleIcon, CloseCircleIcon } from "./style/AuthenticationComponents";

const { Title, Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ConfirmEmail = () => {
	const { t } = useTranslation();
	const { confirmationCode } = useParams() as { confirmationCode: string };
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<ErrorResponse>();
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const navigate = useNavigate();

	usePageTitle(t("common.page_title.emailConfirmation"));

	useEffect(() => {
		Axios.post(`/user/account/confirm_email/${confirmationCode}`)
			.catch((error: ErrorResponse) => {
				setError(error);
				void Axios.error(error, t("dashboard.general.errors.something_went_wrong"));
			})
			.finally(() => setLoading(false));
	}, [t, confirmationCode]);

	if (loading)
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);

	if (error)
		return (
			<ContentCard style={{ alignItems: "center" }}>
				<CloseCircleIcon />
				<Title level={3} style={{ textAlign: "center" }}>
					{t("authentication.confirm_email.confirm_email_error_title")}
				</Title>
				<Text type="secondary" style={{ textAlign: "center" }}>
					{t("authentication.confirm_email.confirm_email_error_subtitle")}
				</Text>
				<Button
					type="primary"
					size="large"
					style={{ marginTop: 48 }}
					onClick={() =>
						navigate(isAuthenticated ? RoutePaths.dashboardApplications : RoutePaths.login)
					}
				>
					{isAuthenticated
						? t("authentication.register.view_my_loans")
						: t("authentication.login.go_to_login")}
				</Button>
			</ContentCard>
		);

	return (
		<ContentCard style={{ alignItems: "center" }}>
			<CheckCircleIcon />
			<Title level={3} style={{ textAlign: "center" }}>
				{t("authentication.confirm_email.confirm_email_success_title")}
			</Title>
			<Text type="secondary" style={{ textAlign: "center" }}>
				{t("authentication.confirm_email.confirm_email_success_subtitle")}
			</Text>
			<Button
				type="primary"
				size="large"
				style={{ marginTop: 48 }}
				onClick={() =>
					navigate(isAuthenticated ? RoutePaths.dashboardApplications : RoutePaths.login)
				}
			>
				{isAuthenticated
					? t("authentication.register.view_my_loans")
					: t("authentication.login.go_to_login")}
			</Button>
		</ContentCard>
	);
};

export default ConfirmEmail;
