import { AlertProps, Alert as AntdAlert } from "antd";
import styled from "styled-components";

const CustomAlert = styled(AntdAlert)`
	align-items: flex-start;

	span {
		margin-top: 4px;
	}
`;

const Alert = (props: AlertProps) => <CustomAlert {...props}></CustomAlert>;

export default Alert;
