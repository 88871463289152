import { useTranslation } from "react-i18next";

import { Steps as AntdSteps } from "antd";
import styled from "styled-components";

const StepsWrapper = styled.div`
	padding: 24px 0;
	background-color: #ffffff;
	width: 100%;
	margin-bottom: 38px;
`;

const Steps = styled(AntdSteps)`
	width: 100%;
	max-width: var(--teylor-header-width);
	margin: 0 auto;
	padding: 0 50px;

	.ant-steps-item-wait .ant-steps-item-icon {
		background: transparent !important;
		border-color: #c5c5c5 !important;

		.ant-steps-icon {
			color: #c5c5c5 !important;
		}
	}

	.ant-steps-item-active .ant-steps-item-icon > .ant-steps-icon {
		color: ${({ theme }) => theme.colorTextLightSolid}!important;
	}
`;

interface Props {
	stepIndex: number;
}

const ApplicationSteps = ({ stepIndex }: Props) => {
	const { t } = useTranslation();

	return (
		<StepsWrapper>
			<Steps
				items={[
					{ title: t("application_process.steps.step1") },
					{ title: t("application_process.steps.step2") },
					{ title: t("application_process.steps.step3") },
					{ title: t("application_process.steps.step4") },
				]}
				current={stepIndex}
				size="small"
			/>
		</StepsWrapper>
	);
};

export default ApplicationSteps;
