import { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Config, FeatureName } from "@teylor-tools/Api";

import { RoutePaths } from "src/Routes";
import i18nInit from "src/i18n/i18nInit";
import { TranslationsProvider } from "src/providers/translations-provider/translations";
import { setConfig } from "src/store/actions/configActions";
import { Axios } from "src/utils/Axios";

import FullPageSpinner from "./components/FullPageSpinner";

export const AppConfig = ({ children }: { children: ReactElement }) => {
	const [configLoaded, setConfigLoaded] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (configLoaded) return;

		Axios.get<Config.ConfigList.RequestQuery, Config.ConfigList.ResponseBody>(`/config`)
			.then(({ data }) => {
				const findFeature = (feature: FeatureName) =>
					data.public_features.find((f) => f.name === feature);
				const currencies = findFeature(FeatureName.Currencies);
				const urlList = findFeature(FeatureName.UrlList);
				const languages = findFeature(FeatureName.CommunicationLanguages);
				const companyName = findFeature(FeatureName.CompanyNames);
				const companyTypes = findFeature(FeatureName.CompanyTypes);
				const preferredCountries = findFeature(FeatureName.ApplicationCountries);
				const userCreatesApplication = findFeature(FeatureName.UserCreateApplication);

				i18nInit({
					languages: languages?.value.supported,
					defaultLanguage: languages?.value.default,
				});

				dispatch(
					setConfig({
						currencies,
						urlList,
						languages,
						companyName,
						companyTypes,
						userCreatesApplication,
						preferredCountries,
					})
				);
			})
			.catch((err) => {
				console.error("Error fetching config", err);
				navigate(RoutePaths.notFound);
			})
			.finally(() => setConfigLoaded(true));
	}, [configLoaded, dispatch, navigate]);

	return configLoaded ? (
		<TranslationsProvider>{children}</TranslationsProvider>
	) : (
		<FullPageSpinner />
	);
};
