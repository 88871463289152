import { useEffect, useMemo, useState } from "react";

import {
	CloudUploadOutlined,
	FileAddOutlined,
	FileTextOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Spin, Tooltip, Typography, Upload, message } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import styled from "styled-components";

import {
	Document,
	DocumentConfigCategory,
	DocumentTypeConfig,
	DocumentTypeName,
	Partner,
	User,
} from "@teylor-tools/Api";
import { AxiosSingleton, ErrorResponse } from "@teylor-tools/utils/constructors/AxiosConstructor";
import { downloadFileFromUrl } from "@teylor-tools/utils/downloadFileFromUrl";

const { Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	margin-top: calc(50vh - 200px);
`;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const UploadRow = styled.div`
	margin: 10px 0;
	width: 100%;

	.ant-upload-drag {
		padding: 4px 18px;
		background: #fff !important;
	}
`;

const IconFileAdd = styled(FileAddOutlined)`
	font-size: 18px;
	color: ${(props) => props.theme.color_primary};
`;

const IconFileText = styled(FileTextOutlined)`
	opacity: 0.35;
	font-size: 18px;
`;

interface Props {
	apiPath: string;
	axios: AxiosSingleton;
	canUpload: boolean;
	categoriesConfig: DocumentConfigCategory[];
	canChangeClientVisibility?: boolean;
	onUpdate: () => void;

	translations: {
		errorGeneric: string;
		fileUploadFail: string;
		fileUploadSuccess: string;
		fileDeleteSuccess: string;
		fileDeleteFail: string;
		errorTryAgain: string;
		errorUploadInProgress: string;
		errorFileNotAvailable: string;
		dragDrop: string;
		uploadBtn: string;
		downloadTemplate: string;
		getTranslation: (key: string) => string;
	};
}
const DocumentsTab = ({
	canUpload,
	axios,
	apiPath,
	translations,
	canChangeClientVisibility = false,
	categoriesConfig,
	onUpdate,
}: Props) => {
	const [documents, setDocuments] = useState<Document[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const allDocTypes: DocumentTypeConfig[] = useMemo(
		() => categoriesConfig?.flatMap((cat) => cat.document_types || []),
		[]
	);

	const refreshDocuments = () => {
		return axios
			.get<
				Partner.ApplicationsDocumentsDetail.RequestQuery,
				Partner.ApplicationsDocumentsDetail.ResponseBody
			>(apiPath, { page_size: 999 })
			.then(({ data }) => {
				setDocuments(data.result);
			})
			.catch((err: ErrorResponse) => {
				void axios.error(err, translations.errorGeneric);
				setDocuments([]);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		refreshDocuments().finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUpload = (options: UploadRequestOption, type: DocumentTypeName) => {
		const { onSuccess, onError } = options;
		const file: RcFile = options.file as RcFile;
		let documentId: string;

		const getUploadInfo = async () =>
			axios.post<
				User.ApplicationsDocumentsCreate.RequestBody,
				User.ApplicationsDocumentsCreate.ResponseBody
			>(apiPath, {
				file_name: file.name,
				document_type: type,
			});

		const uploadFile = async (url: string, file: RcFile) => {
			await axios.put(url, file, {
				headers: {
					"Content-Type": file.type,
					"x-ms-blob-type": "BlockBlob",
				},
			});
		};

		const updateFileStatus = async (documentId: string, isSuccessful: boolean) => {
			await axios.patch(`${apiPath}/${documentId}/upload_success`, {
				is_upload_successful: isSuccessful,
			});
		};

		const manageError = (err: ErrorResponse) => {
			void axios.error(err, translations.fileUploadFail);
			onError && onError({} as Error);

			if (documentId) {
				void updateFileStatus(documentId, false);
			}
		};

		getUploadInfo().then(async ({ data }) => {
			documentId = data.document_id;

			uploadFile(data.upload_url, file).then(() => {
				updateFileStatus(data.document_id, true).then(() => {
					canChangeClientVisibility && toggleClientVisibility(data.document_id, true);
					void message.success(translations.fileUploadSuccess);

					refreshDocuments();
					onUpdate();
					onSuccess && onSuccess("Success");
				}, manageError);
			}, manageError);
		}, manageError);
	};

	const toggleClientVisibility = async (documentId: string, isVisible: boolean) => {
		await axios.patch(`${apiPath}/${documentId}/client_visible`, {
			is_client_visible: isVisible,
		});
	};

	const handleRemove = (fileId: Document["document_id"], showMsg = true) => {
		return axios
			.delete(`${apiPath}/${fileId}`)
			.then(() => {
				if (showMsg) void message.success(translations.fileDeleteSuccess);
				onUpdate();
			})
			.catch((err: ErrorResponse) => {
				if (showMsg) void axios.error(err, translations.fileDeleteFail);
			});
	};

	const handlePreview = (file: UploadFile) => {
		switch (file.status) {
			case "success":
				void axios
					.get<
						Partner.ApplicationsDocumentsDownloadUrlDetail.RequestQuery,
						Partner.ApplicationsDocumentsDownloadUrlDetail.ResponseBody
					>(`${apiPath}/${file.uid}/download_url`)
					.then(({ data }) => {
						window.open(data.download_url);
					})
					.catch((err: ErrorResponse) => void axios.error(err, translations.errorTryAgain));
				break;
			case "uploading":
				void message.error(translations.errorUploadInProgress);
				break;
			default:
				void message.error(translations.errorFileNotAvailable);
		}
	};

	const documentToFile = (doc: Document): UploadFile => {
		return {
			uid: doc.document_id,
			name: doc.document_name,
			fileName: doc.document_name,
			url: doc.s3_key,
			status: "success",
		};
	};

	if (isLoading) {
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);
	}

	return (
		<>
			<Wrapper>
				{canUpload && (
					<div style={{ marginBottom: 32, textAlign: "center" }}>
						<IconFileAdd style={{ marginRight: 8 }} />
						<Text type="secondary">{translations.dragDrop}</Text>
					</div>
				)}

				{allDocTypes.map(
					({ is_required, name, description, template_url, tooltip_description }) => (
						<UploadRow key={`document-upload-section-${name}`}>
							<Upload.Dragger
								fileList={documents.filter((doc) => doc.document_type === name).map(documentToFile)}
								customRequest={(options) => {
									void handleUpload(options, name);
								}}
								onRemove={(file) => {
									void handleRemove(file.uid).then(refreshDocuments);
								}}
								onPreview={handlePreview}
								disabled={!canUpload}
								multiple={true}
							>
								<Row gutter={12} align="middle" style={{ flexFlow: "row" }}>
									<Col flex="auto">
										<Row align="middle" gutter={12}>
											<Col>
												<IconFileText />
											</Col>
											<Col>
												{is_required && (
													<Text type="danger" style={{ marginRight: 4 }}>
														*
													</Text>
												)}
												<Text strong>
													{name && translations.getTranslation(`documents.type.${name}.name`)}
												</Text>
											</Col>
											<Col>
												<Typography.Text type="secondary">
													{description &&
														translations.getTranslation(
															`documents.type.${description}.description`
														)}
												</Typography.Text>
											</Col>
											{tooltip_description && (
												<Col>
													<Tooltip
														placement="top"
														title={translations.getTranslation(
															`documents.type.${tooltip_description}.tooltip`
														)}
													>
														<InfoCircleOutlined style={{ color: "#00000073" }} />
													</Tooltip>
												</Col>
											)}
										</Row>
										{template_url && name && (
											<Row align="middle">
												<Col>
													<Button
														type="link"
														style={{ padding: 0 }}
														onClick={(evt) => {
															evt.stopPropagation();
															downloadFileFromUrl(
																template_url,
																`documents.type.${name}.name`,
																true
															);
														}}
													>
														{translations.downloadTemplate}
													</Button>
												</Col>
											</Row>
										)}
									</Col>
									<Col>
										<Button
											type={`${is_required ? "primary" : "default"}`}
											icon={<CloudUploadOutlined />}
											disabled={!canUpload}
										>
											{translations.uploadBtn}
										</Button>
									</Col>
								</Row>
							</Upload.Dragger>
						</UploadRow>
					)
				)}
			</Wrapper>
		</>
	);
};

export default DocumentsTab;
