import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider as AntdDivider, Layout as AntdLayout, Space, Typography } from "antd";
import styled from "styled-components";

import { RoutePaths } from "src/Routes";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { Content, ContentWrapperNarrow, Layout } from "src/components/layout/LayoutComponents";
import PageHeader from "src/components/layout/page-header/PageHeader";
import { usePageTitle } from "src/hooks/usePageTitle";
import { RootState } from "src/store/reducers/rootReducer";

const { Title, Text } = Typography;
const { Footer: AntdFooter } = AntdLayout;

const Footer = styled(AntdFooter)`
	background-color: #fbfbfb;
	text-align: center;
`;

const FooterLink = styled(Link)`
	margin: 4px;
	color: #00000073;
`;

const Divider = styled(AntdDivider)`
	margin: 40px 0;
`;

const QuestionCircleIcon = styled(QuestionCircleOutlined)`
	display: flex;
	justify-content: center;
	color: var(--teylor-light-gray);
	font-size: 56px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

const NotFoundPage = () => {
	const { t } = useTranslation();
	const isLoggedIn = useSelector((state: RootState) => state.userState.isAuthenticated);
	const configLoaded = useSelector((state: RootState) => state.partnerState.configLoaded);
	const { userCreateApplication } = useSelector((state: RootState) => state.configState);

	usePageTitle(t("common.page_title.notFound"));

	if (!configLoaded) {
		return (
			<Layout>
				<Content style={{ height: "100vh", justifyContent: "center" }}>
					<ContentWrapperNarrow>
						<ContentCard style={{ margin: 0 }}>
							<QuestionCircleIcon />
							<Title level={3} style={{ textAlign: "center" }}>
								{t("not_found_page.title")}
							</Title>
							<Text strong type="secondary" style={{ textAlign: "center" }}>
								{t("not_found_page.subtitle")}
							</Text>
						</ContentCard>
					</ContentWrapperNarrow>
				</Content>
			</Layout>
		);
	}

	return (
		<Layout>
			<PageHeader transparentBg />
			<Content>
				<ContentWrapperNarrow>
					<ContentCard>
						<QuestionCircleIcon />
						<Title level={3} style={{ textAlign: "center" }}>
							{t("not_found_page.title")}
						</Title>
						<Text type="secondary" style={{ textAlign: "center" }}>
							{t("not_found_page.subtitle")}
						</Text>
						<Divider />
						<Space direction="vertical" size={4}>
							<Text type="secondary">{t("not_found_page.links")}</Text>
							{userCreateApplication && (
								<Link to={RoutePaths.applicationLoanInfo}>{t("not_found_page.link_1")}</Link>
							)}
							<Link to={RoutePaths.index}>{t("not_found_page.link_2")}</Link>
						</Space>
					</ContentCard>
				</ContentWrapperNarrow>
			</Content>
			{!isLoggedIn && (
				<Footer>
					<FooterLink to="/">{t("common.label.terms_and_conditions")}</FooterLink>
					<Text type="secondary">|</Text>
					<FooterLink to="/">{t("common.label.privacy_policy")}</FooterLink>
					<Text type="secondary">|</Text>
					<FooterLink to="/">{t("common.label.imprint")}</FooterLink>
				</Footer>
			)}
		</Layout>
	);
};

export default NotFoundPage;
