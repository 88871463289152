import styled from "styled-components";

const ApplicationFormCard = styled.div`
	background-color: #ffffff;
	padding: 40px 112px 80px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media (max-width: 992px) {
		padding: 32px 72px;
	}

	@media (max-width: 576px) {
		padding: 16px 24px;
	}
`;

export default ApplicationFormCard;
