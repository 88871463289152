import { CountryCode, Feature } from "@teylor-tools/Api";
import { CountryList } from "@teylor-tools/utils/i18n/Countries";

export enum ConfigActions {
	SET_CONFIG = "SET_CONFIG",
	SET_COUNTRY_LIST = "SET_COUNTRY_LIST",
}

interface Config {
	currencies?: Feature;
	languages?: Feature;
	urlList?: Feature;
	companyName?: Feature;
	companyTypes?: Feature;
	userCreatesApplication?: Feature;
	preferredCountries?: Feature;
}

export type ConfigAction =
	| { type: ConfigActions.SET_CONFIG; payload: Config }
	| {
			type: ConfigActions.SET_COUNTRY_LIST;
			payload: { preferredCountries?: CountryCode[]; countries: CountryList };
	  };

export const setConfig = (payload: Config): ConfigAction => {
	return {
		type: ConfigActions.SET_CONFIG,
		payload,
	};
};

export const setCountryList = ({
	preferredCountries,
	countries,
}: {
	preferredCountries?: CountryCode[];
	countries: CountryList;
}): ConfigAction => {
	return {
		type: ConfigActions.SET_COUNTRY_LIST,
		payload: {
			preferredCountries,
			countries,
		},
	};
};
