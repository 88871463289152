import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
	Tabs as AntdTabs,
	Breadcrumb,
	Button,
	Spin,
	TabsProps,
	Tooltip,
	Typography,
	message,
} from "antd";
import styled from "styled-components";

import {
	AllBanksLoanPurposes,
	ApplicationStatuses,
	InviteStatus,
	PendingActionType,
	SignatoryRequirements,
	SignatoryResponse,
	StatusPendingAction,
	User,
	UserApplication,
} from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import DocumentsTab from "@ui/modules/DocumentsTab";

import { RoutePaths } from "src/Routes";
import { lgScreen, maxWidth } from "src/breakpoints";
import Alert from "src/components/Alert";
import Error from "src/components/Error";
import Tag from "src/components/Tag";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import CompanyRepresentativesList from "src/pages/dashboard/components/CompanyRepresentativesList";
import ObjectsTab from "src/pages/dashboard/components/ObjectsTab";
import SignatureTab from "src/pages/dashboard/components/SignatureTab/SignatureTab";
import SubmitConfirmationModal from "src/pages/dashboard/components/SubmitConfirmationModal";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { getProfile } from "src/utils/api/authentication";

import ApplicationOverview from "./components/ApplicationOverview";
import { Status, TAG_COLORS, getStatus } from "./utils/status";

const { Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;

const PageWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
`;

const HeadingWrapper = styled.div`
	background: #ffffff;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 64px;
`;

const HeadingContent = styled.div`
	width: var(--teylor-content-width);
	padding: 16px 16px;

	@media (max-width: ${maxWidth(lgScreen)}) {
		max-width: var(--teylor-content-width);
	}
`;

const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const LoanBreadcrumbWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const LoanBreadcrumb = styled(Text)`
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;

	span {
		font-weight: 400;
	}
`;

const Tabs = styled(AntdTabs)`
	.ant-tabs-nav {
		margin-bottom: 40px;

		&::before {
			border-bottom: none;
		}
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: var(--teylor-text-primary);
	}
`;

const Content = styled(ContentWrapper)`
	padding-top: 0;
	position: relative;
	top: -46px; // height of tab list to display it on white background
`;

const InReviewAlert = () => {
	const { t } = useTranslation();
	return (
		<Alert
			type="info"
			message={t("dashboard.general.in_review_alert")}
			showIcon
			style={{ marginBottom: 24 }}
		/>
	);
};

export enum ApplicationTabs {
	OVERVIEW = "OVERVIEW",
	REPRESENTATIVES = "REPRESENTATIVES",
	DOCUMENTS = "DOCUMENTS",
	OBJECTS = "OBJECTS",
	SIGNATURES = "SIGNATURES",
}

export enum PendingItem {
	EMAIL_VERIFICATION,
	BANK_INFORMATION,
	COMPANY_TIN,
	REPRESENTATIVES,
	REPRESENTATIVES_DATA,
	DOCUMENTS,
	OBJECTS,
	SIGNATURE,
}

interface SubmitBtnOptions {
	text: string;
	onSubmit: () => void;
}

const pendingActionToItemMap = new Map<PendingActionType, PendingItem>([
	[PendingActionType.BankNameIbanValid, PendingItem.BANK_INFORMATION],
	[PendingActionType.CompanyTinSpecified, PendingItem.COMPANY_TIN],
	[PendingActionType.SignatoriesSpecified, PendingItem.REPRESENTATIVES],
	[PendingActionType.SignatoriesSoleOrAtleast2, PendingItem.REPRESENTATIVES],
	[PendingActionType.DocumentAnnualStatementsUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.DocumentBwaSusaUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.DocumentCurrentDebtRegisterUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.AllRequiredDocumentsUploaded, PendingItem.DOCUMENTS],
	[PendingActionType.FinanceObjectsAdded, PendingItem.OBJECTS],
	[PendingActionType.SignatoriesTaxidSpecified, PendingItem.SIGNATURE],
	[PendingActionType.SignatoriesIdentCompleted, PendingItem.SIGNATURE],
	[PendingActionType.SepaMandateAccepted, PendingItem.SIGNATURE],
	[PendingActionType.GuarantorContractUploaded, PendingItem.SIGNATURE],
	[PendingActionType.RepresentativesDataCompleted, PendingItem.REPRESENTATIVES_DATA],
	[PendingActionType.SignatoriesMinimumDataCompleted, PendingItem.REPRESENTATIVES_DATA],
]);

const showSignatureTabWithStatus = (
	application: UserApplication,
	requirements: SignatoryRequirements
) => {
	const signatureStatusIndex = application?.status_flow.findIndex(
		(status) => status.status_name === ApplicationStatuses.Signature
	);
	const statusesToShowSignatureTab =
		application?.status_flow.slice(signatureStatusIndex).map((status) => status.status_name) || [];

	const isRequiredToSign =
		requirements.is_video_id_required ||
		requirements.is_loan_signature_required ||
		requirements.is_ubo_signature_required;

	return statusesToShowSignatureTab.includes(application.status) && isRequiredToSign;
};

const getPendingItemsToDisplay = (
	allPendingItems: StatusPendingAction[],
	showSignatureTab: boolean,
	signatoryId?: string
): PendingItem[] => {
	return allPendingItems.reduce<PendingItem[]>((acc, item) => {
		const pendingItem = pendingActionToItemMap.get(item.action);

		if (!pendingItem) return acc;

		// do not show SignatoriesMinimumDataCompleted if SignatoriesSpecified exists, they overlap
		if (
			item.action === PendingActionType.SignatoriesMinimumDataCompleted &&
			allPendingItems.some((action) => action.action === PendingActionType.SignatoriesSpecified)
		)
			return acc;

		if (pendingItem === PendingItem.SIGNATURE) {
			if (signatoryId && showSignatureTab && !acc.includes(PendingItem.SIGNATURE)) {
				const signatoriesAssociatedWithError =
					item.errors &&
					item.errors.reduce<string[]>((acc, error) => {
						error.signatories?.forEach((signatoryId) => acc.push(signatoryId));
						return acc;
					}, []);

				if (signatoriesAssociatedWithError?.includes(signatoryId)) {
					acc.push(pendingItem);
				}
			}

			return acc;
		}

		if (!acc.includes(pendingItem)) {
			acc.push(pendingItem);
		}

		return acc;
	}, []);
};

const DashboardApplicationOverview = () => {
	const { t } = useTranslation();
	const [application, setApplication] = useState<UserApplication>();
	const [representatives, setRepresentatives] = useState<SignatoryResponse[]>([]);
	const [activeTab, setActiveTab] = useState<ApplicationTabs>(ApplicationTabs.OVERVIEW);
	const [loading, setLoading] = useState(true);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [error, setError] = useState<ErrorResponse>();
	const { id } = useParams() as { id: string };
	const { emailVerified, email } = useSelector((state: RootState) => state.userState);
	const [pendingItems, setPendingItems] = useState<StatusPendingAction[]>([]);
	const [pendingItemsToDisplay, setPendingItemsToDisplay] = useState<PendingItem[]>([]);
	const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false);
	const [nextStatus, setNextStatus] = useState<ApplicationStatuses>();
	const { currency } = useFormatter();

	usePageTitle(t("common.page_title.application_overview"));

	const signatory = useMemo(
		() => representatives.find((representative) => representative.email === email),
		[representatives, email]
	);

	const showSignatureTab = useMemo(
		() =>
			application &&
			signatory &&
			signatory.requirements &&
			signatory.invite_status === InviteStatus.Accepted &&
			showSignatureTabWithStatus(application, signatory.requirements),
		[signatory, application]
	);

	const status = useMemo(() => application && getStatus(application.status), [application]);

	const isApplicationInReview = useMemo(
		() => status && [Status.IN_REVIEW, Status.IN_REVIEW_AFTER_SIGNATURE].includes(status),
		[status]
	);

	const representativesTins = useMemo(
		() => representatives.map((rep) => rep.taxid),
		[representatives]
	);

	const pendingItemExists = (actionType: PendingActionType): boolean =>
		pendingItems.some((action) => action.action === actionType);

	const submitApplication = async () => {
		if (!nextStatus) {
			return message.error("dashboard.general.errors.something_went_wrong");
		}

		setLoadingSubmit(true);

		try {
			await Axios.patch<
				User.ApplicationsStatusPartialUpdate.RequestBody,
				User.ApplicationsStatusPartialUpdate.ResponseBody
			>(`/user/applications/${id}/status`, { status: nextStatus });

			await getApplication();
		} catch (err) {
			await Axios.error(err, t("dashboard.general.errors.something_went_wrong"));
		} finally {
			setLoadingSubmit(false);
		}
	};

	const getRepresentatives = () => {
		setLoading(true);
		Axios.get<
			User.ApplicationsSignatoriesDetail.RequestQuery,
			User.ApplicationsSignatoriesDetail.ResponseBody
		>(`/user/applications/${id}/signatories`)
			.then(
				({ data }) => setRepresentatives(data.signatories),
				(err: ErrorResponse) => setError(err)
			)
			.finally(() => setLoading(false));
	};

	const getApplication = async () => {
		await Axios.get<User.ApplicationsDetail.RequestQuery, User.ApplicationsDetail.ResponseBody>(
			`/user/applications/${id}`
		)
			.then(
				(response) => setApplication(response.data),
				(error: ErrorResponse) => setError(error)
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const getPendingItems = () => {
		if (!status) return;

		if (!emailVerified) {
			void getProfile();
		}

		Axios.get<
			User.ApplicationsNextStatusValidateDetail.RequestQuery,
			User.ApplicationsNextStatusValidateDetail.ResponseBody
		>(`/user/applications/${id}/next_status_validate`)
			.then(({ data }) => {
				setPendingItems(data.pending_actions);
				setNextStatus(data.next_status);

				const pendingItemsToDisplay = getPendingItemsToDisplay(
					data.pending_actions,
					!!showSignatureTab,
					signatory?.signatory_id
				);

				setPendingItemsToDisplay([
					...(emailVerified ? [] : [PendingItem.EMAIL_VERIFICATION]),
					...pendingItemsToDisplay,
				]);
			})
			.catch((err: ErrorResponse) => setError(err));
	};

	const handleChangeTab = (tab: ApplicationTabs) => {
		setActiveTab(tab);
		if (tab === ApplicationTabs.OVERVIEW) getPendingItems();
	};

	const submitButton: SubmitBtnOptions | undefined = useMemo(() => {
		if (application && application.status === ApplicationStatuses.Open) {
			return {
				text: t("dashboard.overview.pending_items.submit_application"),
				onSubmit: () => setShowSubmitConfirmationModal(true),
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [application?.status, t]);

	useEffect(() => {
		getRepresentatives();
		void getApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		getPendingItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, emailVerified, status, signatory]);

	const tabs: TabsProps["items"] = useMemo(() => {
		const items = [];

		if (application && status) {
			items.push(
				{
					key: ApplicationTabs.OVERVIEW,
					label: t("dashboard.general.overview"),
					children: (
						<>
							{isApplicationInReview && <InReviewAlert />}
							<ApplicationOverview
								application={application}
								status={status}
								pendingItems={pendingItemsToDisplay}
								getPendingItems={getPendingItems}
								getApplication={() => void getApplication()}
								setActiveTab={setActiveTab}
								representativesTins={representativesTins}
							/>
						</>
					),
				},
				{
					key: ApplicationTabs.REPRESENTATIVES,
					label: t("dashboard.company_representatives.company_representatives"),
					children: (
						<>
							{isApplicationInReview && <InReviewAlert />}
							<CompanyRepresentativesList
								representatives={representatives}
								application={application}
								updateRepresentatives={getRepresentatives}
								getPendingItems={getPendingItems}
								ownershipPercentageExceeded={pendingItemExists(
									PendingActionType.SignatoriesSharesLe100
								)}
								noSoleSignatory={pendingItemExists(PendingActionType.SignatoriesSoleOrAtleast2)}
							/>
						</>
					),
				},
				{
					key: ApplicationTabs.DOCUMENTS,
					label: t("dashboard.documents.documents"),
					children: (
						<>
							{isApplicationInReview && <InReviewAlert />}
							<DocumentsTab
								axios={Axios}
								apiPath={`/user/applications/${application.applicationId}/documents`}
								canUpload={[ApplicationStatuses.Open].includes(application.status)}
								categoriesConfig={application.document_configurations.document_categories || []}
								onUpdate={() => void getPendingItems()}
								translations={{
									errorGeneric: t("dashboard.general.errors.something_went_wrong"),
									fileUploadFail: t("dashboard.documents.errors.file_upload_fail"),
									fileUploadSuccess: t("dashboard.documents.file_upload_success"),
									fileDeleteSuccess: t("dashboard.documents.file_delete_success"),
									fileDeleteFail: t("dashboard.documents.errors.file_delete_fail"),
									errorTryAgain: t("dashboard.general.errors.error_try_again"),
									errorUploadInProgress: t("dashboard.documents.errors.file_upload_in_progress"),
									errorFileNotAvailable: t("dashboard.documents.errors.file_not_available"),
									dragDrop: t("dashboard.documents.drag_drop_below"),
									uploadBtn: t("dashboard.documents.upload"),
									downloadTemplate: t("dashboard.documents.click_here_download_template"),
									// @ts-ignore dynamic values for categories and types
									getTranslation: (key) => t(key),
								}}
							/>
						</>
					),
				}
			);

			if (application.allBanksLoanPurpose === AllBanksLoanPurposes.ObjectFinancing) {
				items.push({
					key: ApplicationTabs.OBJECTS,
					label: t("dashboard.objects.objects"),
					children: (
						<>
							{isApplicationInReview && <InReviewAlert />}
							<ObjectsTab
								applicationStatus={application.status}
								applicationId={application.applicationId}
								onUpdate={() => void getPendingItems()}
								currency={application.currency}
							/>
						</>
					),
				});
			}

			if (showSignatureTab && signatory) {
				items.push({
					key: ApplicationTabs.SIGNATURES,
					label: t("dashboard.signature.signatures"),
					children: (
						<SignatureTab
							application={application}
							signatory={signatory}
							updateRepresentatives={getRepresentatives}
						/>
					),
				});
			}
		}

		return items;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isApplicationInReview,
		pendingItemsToDisplay,
		pendingItemExists,
		status,
		id,
		representatives,
		application,
		showSignatureTab,
		signatory,
		application?.allBanksLoanPurpose,
	]);

	if (loading) {
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);
	}

	if (error) return <Error error={error} />;
	if (!application || !status) return null;

	return (
		<>
			<PageWrapper>
				<HeadingWrapper>
					<HeadingContent>
						<Heading>
							<LoanBreadcrumbWrapper>
								<Breadcrumb>
									<Breadcrumb.Item>
										<Link to={RoutePaths.dashboardApplications}>
											{t("dashboard.general.loans")}
										</Link>
									</Breadcrumb.Item>
									<Breadcrumb.Item>
										<LoanBreadcrumb>
											{currency(application.loanSize, {
												showFractions: false,
												currency: application.currency,
											})}
											<span>({application.shortApplicationId})</span>
										</LoanBreadcrumb>
									</Breadcrumb.Item>
								</Breadcrumb>
								{application.status && (
									<Tag style={{ marginLeft: 8 }} color={TAG_COLORS[status]}>
										{t(`common.application_info.application_status.${status}`)}
									</Tag>
								)}
							</LoanBreadcrumbWrapper>
							{submitButton && (
								<Tooltip
									title={
										status === Status.NOT_COMPLETED
											? t("dashboard.overview.pending_items.submit_application_tooltip")
											: ""
									}
								>
									<Button
										type="primary"
										disabled={!!pendingItemsToDisplay.length}
										loading={loadingSubmit}
										onClick={submitButton.onSubmit}
										data-cy="submit-application-button"
									>
										{submitButton.text}
									</Button>
								</Tooltip>
							)}
						</Heading>
					</HeadingContent>
				</HeadingWrapper>
				<Content>
					<Tabs
						activeKey={activeTab}
						onChange={(v) => handleChangeTab(v as ApplicationTabs)}
						destroyInactiveTabPane
						items={tabs}
					/>
				</Content>
			</PageWrapper>
			<SubmitConfirmationModal
				representatives={representatives}
				modalProps={{
					open: showSubmitConfirmationModal,
					onCancel: () => {
						setShowSubmitConfirmationModal(false);
					},
					onOk: () => {
						void submitApplication();
						setShowSubmitConfirmationModal(false);
					},
				}}
			/>
		</>
	);
};

export default DashboardApplicationOverview;
