import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { EuroOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import styled from "styled-components";

import { RoutePaths } from "src/Routes";
import { logout, resetProfile } from "src/store/actions/userActions";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const UserIcon = styled(UserOutlined)`
	display: flex !important;
	height: 26px;
	width: 26px;
	background-color: var(--teylor-grey-border);
	border-radius: 50%;
	color: var(--teylor-text-secondary);
	justify-content: center;
	align-items: center;
	font-size: 11px;
`;

const UserButton = styled(Button)`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: var(--teylor-text-secondary);
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		background-color: transparent !important;
		color: var(--teylor-text-secondary);
	}
`;

const UserActions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { email } = useSelector((state: RootState) => state.userState);

	const logOut = () => {
		return Axios.post<never, never>("/user/account/logout").then(
			() => {
				dispatch(logout(true));
				dispatch(resetProfile());
				navigate(RoutePaths.index);
			},
			(error: ErrorResponse) => Axios.error(error, t("authentication.errors.logout_error"))
		);
	};

	const menuItems: MenuProps["items"] = useMemo(
		() => [
			{
				key: "my-loans",
				icon: <EuroOutlined data-cy="user-applications" />,
				label: t("common.page_title.application_list"),
				onClick: () => navigate(RoutePaths.dashboardApplications),
			},
			{
				key: "settings",
				icon: <SettingOutlined data-cy="user-settings" />,
				label: t("common.page_title.settings"),
				onClick: () => navigate(RoutePaths.dashboardSettings),
			},
			{
				key: "logout",
				icon: <LogoutOutlined data-cy="user-logout" />,
				label: t("dashboard.general.log_out"),
				onClick: () => {
					void logOut();
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	);

	return (
		<Dropdown menu={{ items: menuItems }} placement="bottomRight" arrow>
			<UserButton type="text" data-cy="profile-button">
				{email} <UserIcon />
			</UserButton>
		</Dropdown>
	);
};

export default UserActions;
