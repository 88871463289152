import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Col, Form, Input, Row, Select, Space, Typography } from "antd";

import CityFormItem from "@ui/form/form-items/city-form-item/CityFormItem";
import CountrySelectFormItem from "@ui/form/form-items/country-select-form-item/CountrySelectFormItem";
import HouseNumberFormItem from "@ui/form/form-items/house-number-form-item/HouseNumberFormItem";
import PostcodeFormItem from "@ui/form/form-items/postcode-form-item/PostcodeFormItem";
import StreetFormItem from "@ui/form/form-items/street-form-item/StreetFormItem";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";

import { RoutePaths } from "src/Routes";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import ApplicationFormBackButton from "src/pages/application-form/components/ApplicationFormBackButton";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import {
	CompanyDetails,
	useApplicationForm,
} from "src/providers/application-form-provider/useApplicationForm";
import { RootState } from "src/store/reducers/rootReducer";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ApplicationCompanyDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const { userApplicationTypes } = useSelector((state: RootState) => state.partnerState);
	const { companyTypes, countries } = useSelector((state: RootState) => state.configState);
	const [form] = Form.useForm<CompanyDetails>();
	const { applicationForm, setApplicationForm, isSelectedCompany } = useApplicationForm();
	usePageTitle(t("common.page_title.new_application"));

	const requiredRule = {
		required: true,
		message: t("application_process.error_messages.field_required"),
	};

	const onFinish = (companyDetails: CompanyDetails) => {
		setApplicationForm((prevState) => ({
			...prevState,
			...companyDetails,
			allBanksLoanPurpose: companyDetails.allBanksLoanPurpose || userApplicationTypes[0],
			taxIdNumber: parseTin(companyDetails.taxIdNumber),
		}));
		navigate(
			isAuthenticated ? RoutePaths.applicationResult : RoutePaths.applicationAccountCreation
		);
	};

	return (
		<>
			<ApplicationSteps stepIndex={2} />
			<ContentWrapper>
				<ApplicationFormCard>
					<ApplicationFormBackButton path={RoutePaths.applicationCompanyName} />
					<Space direction="vertical" size={24}>
						<Title level={3}>
							{t("application_process.company_details.company_details_title")}
						</Title>
						<Form form={form} layout="vertical" onFinish={onFinish} initialValues={applicationForm}>
							<Form.Item
								rules={[requiredRule]}
								label={t("application_process.company_details.company_type")}
								name="companyType"
							>
								<Select
									placeholder={t("application_process.company_details.company_type_placeholder")}
									data-cy="company-type"
								>
									{companyTypes?.map((companyType) => (
										<Option key={companyType} data-cy="company-type-option">
											{t(`common.company_info.company_types.${companyType}`)}
										</Option>
									))}
								</Select>
							</Form.Item>
							{!isSelectedCompany && (
								<>
									<Row gutter={16}>
										<Col sm={12}>
											<StreetFormItem data-cy="company-street" name="street" required />
										</Col>
										<Col sm={12}>
											<HouseNumberFormItem
												data-cy="company-houseNumber"
												name="houseNumber"
												required
											/>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col sm={12}>
											<CityFormItem data-cy="company-location" name="companyLocation" required />
										</Col>
										<Col sm={12}>
											<PostcodeFormItem name="companyPostCode" required />
										</Col>
									</Row>

									<CountrySelectFormItem
										name="companyCountry"
										label={t("application_process.company_details.country")}
										rules={[requiredRule]}
										countries={countries}
									/>
								</>
							)}
							{userApplicationTypes.length > 1 && (
								<Form.Item
									name="allBanksLoanPurpose"
									label={t("common.loan_type.loan_type")}
									rules={[requiredRule]}
								>
									<Select data-cy="loan-purpose-form">
										{userApplicationTypes.map((type) => (
											<Select.Option key={type} value={type} data-cy="loan-purpose-option">
												{t(`common.loan_type.${type}`)}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							)}
							<Form.Item
								rules={[
									{
										min: 5,
										message: t("common.input_field.validation.min_chars", {
											min: 5,
										}),
									},
									{
										max: 200,
										message: t("common.input_field.validation.max_chars", {
											max: 200,
										}),
									},
									requiredRule,
								]}
								label={t("application_process.company_details.business_purpose")}
								name="businessPurpose"
							>
								<Input
									maxLength={200}
									placeholder={t(
										"application_process.company_details.business_purpose_placeholder"
									)}
									data-cy="company-purpose"
								/>
							</Form.Item>
							<Form.Item
								rules={[
									{
										min: 30,
										message: t("common.input_field.validation.min_chars", { min: 30 }),
									},
									requiredRule,
								]}
								label={
									<Space size={4}>
										<Text>{t("application_process.company_details.detailed_loan_purpose")}</Text>
										<Text type="secondary">
											{t("application_process.company_details.detailed_loan_purpose_limit")}
										</Text>
									</Space>
								}
								name="purposeDescription"
							>
								<TextArea
									showCount
									rows={6}
									maxLength={700}
									placeholder={t(
										"application_process.company_details.detailed_loan_purpose_placeholder"
									)}
									data-cy="company-detailed-purpose"
								/>
							</Form.Item>
							<TinFormItem
								name={"taxIdNumber"}
								label={
									<Space size={4}>
										<Text>{t("application_process.company_details.tax_number")}</Text>
										<Text type="secondary" style={{ fontSize: 12 }}>
											{t("application_process.company_details.optional")}
										</Text>
									</Space>
								}
								inputProps={{
									placeholder: t("application_process.company_details.tax_number_placeholder"),
								}}
							/>
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton
										type="primary"
										size="large"
										htmlType="submit"
										data-cy="next-button"
									>
										{t("common.button_label.next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationCompanyDetails;
