import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { DownOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import {
	Button as AntdButton,
	Layout as AntdLayout,
	Dropdown,
	MenuProps,
	Popover,
	Space,
	Typography,
	message,
} from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import styled from "styled-components";

import { User } from "@teylor-tools/Api";

import { RoutePaths } from "src/Routes";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios } from "src/utils/Axios";

import UserActions from "./UserActions";

const { Header: BaseHeader } = AntdLayout;
const { Text } = Typography;

const HeaderWrapper = styled.div<{ transparentBg: boolean }>`
	background-color: ${({ transparentBg }) => (transparentBg ? "transparent" : "#fff")};
`;

const Header = styled(BaseHeader)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: var(--teylor-header-width);
	margin: 0 auto;
	background-color: transparent !important;
`;

const Logo = styled.img.attrs((props) => ({
	src: props.theme.logo_web_path,
	alt: "Logo",
	height: 40,
}))``;

const HeaderItemsWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 24px;
`;

const HeaderItemTitle = styled(AntdButton)`
	padding: 0;

	&:hover,
	&:active,
	&:focus {
		background: none !important;
	}
`;

const Divider = styled.span`
	display: inline-block;
	width: 1px;
	height: 32px;
	background-color: #f0f0f0;
`;

const LogoLink = styled(Link)`
	display: flex;
`;

type MenuItem = Required<MenuProps>["items"][number];

interface Props {
	showUserArea?: boolean;
	transparentBg?: boolean;
}

const PageHeader = ({ showUserArea = true, transparentBg = false }: Props) => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const { customer_support_phone, customer_support_email } = useSelector(
		(rootState: RootState) => rootState.partnerState
	);
	const { userId, isAuthenticated } = useSelector((state: RootState) => state.userState);
	const { languages } = useSelector((state: RootState) => state.configState);

	const updateUserLanguage = async ({ language }: { language: string }) => {
		await Axios.patch<
			User.ProfilePartialUpdate.RequestBody,
			User.ProfilePartialUpdate.ResponseBody
		>(`/user/profile/${userId}`, { interface_language: language })
			.then(() => {
				void i18n.changeLanguage(language);
			})
			.catch(() => {
				void message.error(t("settings.account.language-not-updated"));
			});
	};

	const changeLanguage = (v: MenuInfo) => {
		if (userId) {
			void updateUserLanguage({ language: v.key });
		} else {
			void i18n.changeLanguage(v.key);
		}
	};

	const languageItems: MenuItem[] = languages?.length
		? languages.map((lang) => ({
				key: lang,
				label: lang.toUpperCase(),
		  }))
		: [];

	return (
		<HeaderWrapper transparentBg={transparentBg}>
			<Header>
				<LogoLink to={RoutePaths.index}>
					<Logo />
				</LogoLink>

				<HeaderItemsWrapper>
					<Popover
						content={
							<Space direction="vertical" style={{ minWidth: 200 }}>
								<Text type="secondary">{t("dashboard.general.headerNeedHelp")}</Text>
								{customer_support_phone && (
									<Space>
										<PhoneOutlined />
										<Text>{customer_support_phone}</Text>
									</Space>
								)}
								{customer_support_email && (
									<Space>
										<MailOutlined />
										<Text>{customer_support_email}</Text>
									</Space>
								)}
							</Space>
						}
						placement="topRight"
					>
						<HeaderItemTitle type="text">{t("dashboard.general.headerContact")}</HeaderItemTitle>
					</Popover>

					<Dropdown
						menu={{
							items: languageItems,
							onClick: (v) => changeLanguage(v),
							style: { textAlign: "center", minWidth: 50 },
						}}
						arrow={true}
					>
						<HeaderItemTitle type="text" data-cy="language-switch">
							{i18n.language.toUpperCase()} <DownOutlined />
						</HeaderItemTitle>
					</Dropdown>

					{showUserArea && (
						<>
							<Divider />

							{isAuthenticated ? (
								<UserActions />
							) : (
								<AntdButton type="primary" onClick={() => navigate(RoutePaths.login)}>
									{t("common.button_label.login")}
								</AntdButton>
							)}
						</>
					)}
				</HeaderItemsWrapper>
			</Header>
		</HeaderWrapper>
	);
};

export default PageHeader;
