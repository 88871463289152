import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, FormProps, Modal, Space, message } from "antd";
import styled from "styled-components";

import { ApplicationStatuses, SignatoryWithId } from "@teylor-tools/Api";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";
import RepresentativeCard from "@ui/representative-card/RepresentativeCard";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";

import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined)`
	color: ${({ theme }) => theme.colorError} !important;
`;

interface Props {
	representative: SignatoryWithId;
	onDeleteOrUpdate: () => void;
	onUpdateTin: () => void;
	applicationStatus: ApplicationStatuses;
	companyTin: string;
}

interface TinFormProps extends FormProps {
	taxid: string;
}

const TIN_FORM = "tin-form";

const tinNotEditableStatuses = [
	ApplicationStatuses.Open,
	ApplicationStatuses.FinalReview,
	ApplicationStatuses.AwaitingPayout,
	ApplicationStatuses.PaidOut,
	ApplicationStatuses.Rejected,
];

const CompanyRepresentative = ({
	representative,
	onDeleteOrUpdate,
	onUpdateTin,
	applicationStatus,
	companyTin,
}: Props) => {
	const { t } = useTranslation();
	const { confirm } = Modal;
	const [tinModalVisibility, setTinModalVisibility] = useState(false);
	const [isRepresentativeModalOpen, setIsRepresentativeModalOpen] = useState(false);
	const { countries } = useSelector((state: RootState) => state.configState);

	const [form] = Form.useForm<TinFormProps>();

	const representativeName = `${representative.first_name} ${representative.last_name}`;

	const showEditTinButton = !tinNotEditableStatuses.includes(applicationStatus);

	const deleteRepresentative = () => {
		confirm({
			title: t("dashboard.company_representatives.delete_modal_title"),
			icon: <ExclamationCircleOutlinedIcon />,
			content: (
				<Trans i18nKey="dashboard.company_representatives.delete_representative_info">
					{{ name: representativeName }}
				</Trans>
			),
			okText: t("common.button_label.delete"),
			okButtonProps: { danger: true },
			cancelText: t("common.button_label.cancel"),
			onOk() {
				Axios.delete(`/user/signatories/${representative.signatory_id || ""}`).then(
					() => onDeleteOrUpdate(),
					(err: ErrorResponse) => {
						void Axios.error(
							err,
							t("dashboard.company_representatives.errors.dashboard_delete_representative_error")
						);
					}
				);
			},
		});
	};

	const handleUpdateTin = ({ taxid }: TinFormProps) => {
		Axios.patch<SignatoryWithId, never>(`/user/signatories/${representative.signatory_id}`, {
			...representative,
			taxid: parseTin(taxid),
		}).then(
			() => {
				closeTinModal();
				void message.success(t("dashboard.company_representatives.tin_added_successfully"));
				onUpdateTin();
			},
			(err: ErrorResponse) => Axios.error(err, t("dashboard.overview.errors.error_updating_tin"))
		);
	};

	const editRepresentative = (signatory: SignatoryWithId) => {
		Axios.patch(`/user/signatories/${representative.signatory_id}`, {
			...signatory,
		}).then(
			() => {
				void message.success("Changes saved successfully");
				onDeleteOrUpdate();
			},
			(err: ErrorResponse) => Axios.error(err, t("dashboard.general.errors.error_try_again"))
		);
	};

	const closeTinModal = () => {
		setTinModalVisibility(false);
		form.resetFields();
	};

	return (
		<>
			<RepresentativeCard
				representative={representative}
				showEditTinButton={showEditTinButton}
				onEditTin={() => setTinModalVisibility(true)}
				onEditRepresentative={() => setIsRepresentativeModalOpen(true)}
				onDeleteRepresentative={deleteRepresentative}
				showExtra={applicationStatus === ApplicationStatuses.Open}
			/>

			<Modal
				title={
					representative.taxid
						? t("dashboard.company_representatives.edit_tin")
						: t("dashboard.company_representatives.add_tin")
				}
				open={tinModalVisibility}
				onCancel={closeTinModal}
				cancelText={t("common.button_label.cancel")}
				okText={t("common.button_label.save")}
				okButtonProps={{ htmlType: "submit", form: TIN_FORM }}
			>
				<Form id={TIN_FORM} form={form} layout="vertical" onFinish={handleUpdateTin}>
					<TinFormItem
						type="personal"
						name="taxid"
						initialValue={representative.taxid}
						rules={[
							{ required: true, message: t("application_process.error_messages.field_required") },
							{
								validator: (rule, value: string) =>
									validateValueDifferentThan({
										value: parseTin(value),
										differentThan: companyTin,
										error: t("dashboard.errors.personal_tin_must_be_different_than_company_tin"),
									}),
							},
						]}
						label={
							<Space size={4}>
								<Trans i18nKey="dashboard.company_representatives.tin_for_person">
									{{
										name: representativeName,
									}}
								</Trans>
							</Space>
						}
						inputProps={{
							placeholder: t("application_process.company_details.tax_number_placeholder"),
						}}
					/>
				</Form>
			</Modal>
			{isRepresentativeModalOpen && (
				<RepresentativeModal
					save={editRepresentative}
					close={() => setIsRepresentativeModalOpen(false)}
					representative={representative}
					countries={countries}
					companyTin={companyTin}
				/>
			)}
		</>
	);
};

export default CompanyRepresentative;
