import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";

import { EntitlementType } from "@teylor-tools/Api";

import { RoutePaths } from "src/Routes";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import LoanSlider, {
	AMOUNT_INPUT_CONFIG,
	AMOUNT_SLIDER_CONFIG,
	DURATION_INPUT_CONFIG,
	DURATION_SLIDER_CONFIG,
} from "src/pages/application-form/components/LoanSlider";
import {
	LoanDetails,
	useApplicationForm,
} from "src/providers/application-form-provider/useApplicationForm";
import { RootState } from "src/store/reducers/rootReducer";

const { Title } = Typography;

const ApplicationLoanInfo = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { entitlements } = useSelector((state: RootState) => state.partnerState);
	const [searchParams] = useSearchParams();
	usePageTitle(t("common.page_title.new_application"));

	const {
		applicationForm: { loanSize, loanDuration },
		setApplicationForm,
	} = useApplicationForm();

	const disableSliderInput = !entitlements.includes(EntitlementType.CustomizedApplicationAmounts);

	const {
		loan_amount: queryLoanAmount,
		loan_duration: queryLoanDuration,
		ref: referrer,
	} = Object.fromEntries(searchParams);

	const initialAmount = useMemo(() => {
		return queryLoanAmount &&
			Number(queryLoanAmount) >= AMOUNT_SLIDER_CONFIG.min &&
			Number(queryLoanAmount) <= AMOUNT_INPUT_CONFIG.max
			? Number(queryLoanAmount)
			: loanSize;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialDuration = useMemo(() => {
		return queryLoanDuration &&
			Number(queryLoanDuration) >= DURATION_SLIDER_CONFIG.min &&
			Number(queryLoanDuration) <= DURATION_INPUT_CONFIG.max
			? Number(queryLoanDuration)
			: loanDuration;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setApplicationForm((prevState) => ({
			...prevState,
			loanSize: initialAmount,
			loanDuration: initialDuration,
			referrer,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFinish = ({ loanSize, loanDuration }: LoanDetails) => {
		setApplicationForm((prevState) => ({ ...prevState, loanSize, loanDuration }));
		navigate(RoutePaths.applicationCompanyName);
	};

	return (
		<>
			<ApplicationSteps stepIndex={0} />
			<ContentWrapper>
				<ApplicationFormCard>
					<Space direction="vertical" size={24}>
						<Title level={3} data-cy="loan-information-title">
							{t("application_process.loan_information.loan_info_title")}
						</Title>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<LoanSlider
								type="LoanAmount"
								initialValue={initialAmount}
								name="loanSize"
								disableInput={disableSliderInput}
							/>
							<LoanSlider
								type="LoanDuration"
								initialValue={initialDuration}
								name="loanDuration"
								disableInput={disableSliderInput}
							/>
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton
										type="primary"
										size="large"
										htmlType="submit"
										data-cy="loan-info-next-button"
									>
										{t("common.button_label.next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationLoanInfo;
