import { Spin } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const GreySpin = styled(Spin)`
	.ant-spin-dot-item {
		background-color: grey;
	}
`;

const FullPageSpinner = () => {
	return (
		<Wrapper>
			<GreySpin size="large" />
		</Wrapper>
	);
};

export default FullPageSpinner;
