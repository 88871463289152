import { Dispatch, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { ClockCircleOutlined, EditOutlined } from "@ant-design/icons";
import {
	Divider as AntdDivider,
	Button,
	Card,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Space,
	Typography,
	message,
} from "antd";
import dayjs from "dayjs";
import i18n from "i18next";
import { isValid } from "iban";
import styled from "styled-components";

import { User, UserApplication } from "@teylor-tools/Api";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";
import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";

import { ApplicationTabs, PendingItem } from "src/pages/dashboard/DashboardApplicationOverview";
import { Axios, ErrorResponse } from "src/utils/Axios";

import { Status } from "../utils/status";
import LoanDetails from "./LoanDetails";

const { Text } = Typography;

const Property = styled(Col)`
	text-align: right;
`;

const PendingCardTitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px 0;
`;

const PendingCardSubtitle = styled(Text)`
	font-size: 14px;
	font-weight: 400;
	margin-top: 8px;
`;

const PendingItemWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Divider = styled(AntdDivider)`
	margin: 16px 0;
`;

const AddTin = styled(Text)`
	color: ${({ theme }) => theme.colorLink};
	cursor: pointer;
`;

const EditIcon = styled(EditOutlined)`
	color: ${({ theme }) => theme.colorLink};
	margin-left: 4px;
`;

const BANK_DETAILS_FORM = "bank-details-form";
const TIN_FORM = "tin-form";

interface BankDetails {
	bankName: string;
	iban: string;
}

interface Props {
	application: UserApplication;
	status: Status;
	pendingItems: PendingItem[];
	getPendingItems: () => void;
	getApplication: () => void;
	setActiveTab: Dispatch<React.SetStateAction<ApplicationTabs>>;
	representativesTins: string[];
}

const ApplicationOverview = ({
	application,
	status,
	pendingItems,
	getApplication,
	setActiveTab,
	getPendingItems,
	representativesTins,
}: Props) => {
	const { t } = useTranslation();
	const [loadingResendVerificationEmail, setLoadingResendVerificationEmail] = useState(false);
	const [isBankModalVisible, setIsBankModalVisible] = useState(false);
	const [isTinModalVisible, setIsTinModalVisible] = useState(false);

	const {
		applicationId,
		bankName,
		iban,
		taxIdNumber,
		companyName,
		companyType,
		street,
		houseNumber,
		companyPostCode,
		companyCountry,
		companyLocation,
		hrNumber,
		companyFoundationDate,
	} = application;

	const [form] = Form.useForm();

	const hasBankDetails = !!(bankName && iban);

	const resendVerificationEmail = () => {
		setLoadingResendVerificationEmail(true);
		Axios.post<
			User.AccountResendConfEmailCreate.RequestBody,
			User.AccountResendConfEmailCreate.ResponseBody
		>(`/user/account/resend_conf_email`)
			.then(
				() => void message.success(t("dashboard.general.verification_email_send")),
				(error: ErrorResponse) =>
					Axios.error(error, t("dashboard.general.errors.something_went_wrong"))
			)
			.finally(() => setLoadingResendVerificationEmail(false));
	};

	const handleUpdateBankInformation = ({ bankName, iban }: BankDetails) => {
		if (!applicationId) return;
		Axios.patch<
			User.ApplicationsBankinfoPartialUpdate.RequestBody,
			User.ApplicationsBankinfoPartialUpdate.ResponseBody
		>(`/user/applications/${applicationId}/bankinfo`, {
			bankName,
			iban,
		}).then(
			() => {
				getApplication();
				setIsBankModalVisible(false);
				getPendingItems();
			},
			(error: ErrorResponse) => {
				void Axios.error(error, t("dashboard.general.errors.something_went_wrong"));
			}
		);
	};

	const handleUpdateTin = ({ taxIdNumber }: { taxIdNumber: string }) => {
		Axios.patch<
			User.ApplicationsPartialUpdate.RequestBody,
			User.ApplicationsPartialUpdate.ResponseBody
		>(`/user/applications/${applicationId}`, { taxIdNumber: parseTin(taxIdNumber) }).then(
			() => {
				getApplication();
				setIsTinModalVisible(false);
				getPendingItems();
			},
			(error: ErrorResponse) => {
				void Axios.error(error, t("dashboard.general.errors.something_went_wrong"));
			}
		);
	};

	return (
		<>
			<Row gutter={[40, 40]}>
				<Col span={24}>
					<LoanDetails application={application} />
				</Col>
				{!!pendingItems?.length && (
					<Col span={24}>
						<Card
							title={
								<PendingCardTitleWrapper>
									<Text type="danger" style={{ fontSize: 16 }}>
										<ClockCircleOutlined
											style={{
												marginRight: 8,
											}}
										/>
										{t("dashboard.general.pending_items.pending_items")}
									</Text>
									<PendingCardSubtitle type="secondary">
										{t("dashboard.overview.pending_items.pending_items_subtitle")}
									</PendingCardSubtitle>
								</PendingCardTitleWrapper>
							}
						>
							{pendingItems.map((item, idx) => {
								switch (item) {
									case PendingItem.EMAIL_VERIFICATION:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.overview.pending_items.confirm_email")}</Text>
													<Button
														onClick={resendVerificationEmail}
														loading={loadingResendVerificationEmail}
													>
														{t("dashboard.overview.pending_items.resend_email")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.BANK_INFORMATION:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.overview.pending_items.add_bank_information")}</Text>
													<Button onClick={() => setIsBankModalVisible(true)}>
														{t("common.button_label.add")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.COMPANY_TIN:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.overview.pending_items.add_tin")}</Text>
													<Button onClick={() => setIsTinModalVisible(true)}>
														{t("common.button_label.add")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.REPRESENTATIVES:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.overview.pending_items.add_representatives")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.REPRESENTATIVES)}>
														{t("common.button_label.add")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.REPRESENTATIVES_DATA:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>
														{t("dashboard.overview.pending_items.complete_representatives_data")}
													</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.REPRESENTATIVES)}>
														{t("common.button_label.edit")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.DOCUMENTS:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.signature.upload_documents")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.DOCUMENTS)}>
														{t("dashboard.documents.upload")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.OBJECTS:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.objects.addObjects")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.OBJECTS)}>
														{t("common.button_label.add")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
									case PendingItem.SIGNATURE:
										return (
											<Fragment key={idx}>
												{!!idx && <Divider />}
												<PendingItemWrapper>
													<Text>{t("dashboard.signature.complete_digital_signature")}</Text>
													<Button onClick={() => setActiveTab(ApplicationTabs.SIGNATURES)}>
														{t("dashboard.signature.sign")}
													</Button>
												</PendingItemWrapper>
											</Fragment>
										);
								}
							})}
						</Card>
					</Col>
				)}
				<Col span={24}>
					<Card title={t("dashboard.overview.company_information.company_information")}>
						<Row gutter={32}>
							<Col xs={24} sm={12}>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">
											{t("dashboard.overview.company_information.name")}:
										</Text>
									</Property>
									<Col>{companyName}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">
											{t("application_process.company_details.company_type")}:
										</Text>
									</Property>
									<Col>{t(`common.company_info.company_types.${companyType}`)}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">
											{t("dashboard.overview.company_information.address")}:
										</Text>
									</Property>
									<Col
										style={{
											whiteSpace: "pre-line",
										}}
									>{`${street || ""} ${houseNumber || ""}
									${companyPostCode || ""}, ${companyLocation || ""}
									${getCountryList(i18n.language)[companyCountry] || ""}`}</Col>
								</Row>
							</Col>
							<Col xs={24} sm={12}>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">
											{t("application_process.company_details.hr_number")}:
										</Text>
									</Property>
									<Col>{hrNumber || "-"}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">
											{t("dashboard.overview.company_information.founded")}:
										</Text>
									</Property>
									<Col>{dayjs(companyFoundationDate).format(yearMonthDay)}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={8}>
										<Text type="secondary">
											{t("dashboard.overview.company_information.tax_number")}:
										</Text>
									</Property>
									<Col>
										{taxIdNumber ? (
											<>
												{taxIdNumber}
												{status === Status.NOT_COMPLETED && (
													<EditIcon
														onClick={() => {
															setIsTinModalVisible(true);
														}}
													/>
												)}
											</>
										) : (
											<AddTin onClick={() => setIsTinModalVisible(true)}>
												{t("dashboard.company_representatives.add_tin")}
											</AddTin>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={24}>
					<Card
						title={t("dashboard.overview.bank_information.bank_information")}
						data-cy="bank-information-section"
						extra={
							hasBankDetails &&
							status === Status.NOT_COMPLETED && (
								<Button
									type="link"
									icon={<EditOutlined />}
									onClick={() => setIsBankModalVisible(true)}
								>
									{t("common.button_label.change")}
								</Button>
							)
						}
					>
						{hasBankDetails ? (
							<>
								<Row gutter={8}>
									<Property span={4}>
										<Text type="secondary">
											{t("dashboard.overview.bank_information.bank_name")}:
										</Text>
									</Property>
									<Col>{bankName}</Col>
								</Row>
								<Row gutter={8}>
									<Property span={4}>
										<Text type="secondary">{t("dashboard.overview.bank_information.iban")}:</Text>
									</Property>
									<Col>{iban}</Col>
								</Row>
							</>
						) : (
							<Space direction="vertical" size={16} align="center" style={{ width: "100%" }}>
								<Text type="secondary">{t("dashboard.general.no_information_added")}</Text>
								<Button
									type="primary"
									onClick={() => setIsBankModalVisible(true)}
									data-cy="add-bank-information-button"
								>
									{t("dashboard.overview.pending_items.add_bank_information")}
								</Button>
							</Space>
						)}
					</Card>
				</Col>
			</Row>
			<Modal
				title={t("dashboard.overview.bank_information.company_bank_information")}
				open={isBankModalVisible}
				onCancel={() => setIsBankModalVisible(false)}
				cancelText={t("common.button_label.cancel")}
				okText={t("common.button_label.save")}
				okButtonProps={{ htmlType: "submit", form: BANK_DETAILS_FORM }}
			>
				<Form
					id={BANK_DETAILS_FORM}
					form={form}
					layout="vertical"
					onFinish={handleUpdateBankInformation}
					initialValues={{
						bankName: bankName || "",
						iban: iban || "",
					}}
				>
					<Form.Item
						rules={[
							{
								required: true,
								min: 1,
								max: 100,
								message: t("dashboard.overview.errors.bank_name_invalid"),
							},
						]}
						label={t("dashboard.overview.bank_information.bank_name")}
						name="bankName"
					>
						<Input
							maxLength={100}
							placeholder={t("dashboard.overview.bank_information.bank_name_placeholder")}
							data-cy="bank-name-input"
						/>
					</Form.Item>
					<Form.Item
						rules={[
							{
								required: true,
								message: t("application_process.error_messages.field_required"),
							},
							{
								validator: (_, iban: string = "") => {
									return !iban.includes("_") && isValid(iban)
										? Promise.resolve()
										: Promise.reject(t("dashboard.overview.errors.iban_invalid"));
								},
							},
						]}
						label={t("dashboard.overview.bank_information.iban_label")}
						name="iban"
					>
						<Input
							maxLength={34}
							placeholder={t("dashboard.overview.bank_information.iban_placeholder")}
							data-cy="bank-iban-input"
						/>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title={t("dashboard.overview.pending_items.company_tin")}
				open={isTinModalVisible}
				onCancel={() => setIsTinModalVisible(false)}
				cancelText={t("common.button_label.cancel")}
				okText={t("common.button_label.save")}
				okButtonProps={{ htmlType: "submit", form: TIN_FORM }}
			>
				<Form
					id={TIN_FORM}
					form={form}
					layout="vertical"
					onFinish={handleUpdateTin}
					initialValues={{ taxIdNumber: taxIdNumber || "" }}
				>
					<TinFormItem
						name="taxIdNumber"
						rules={[
							{ required: true, message: t("application_process.error_messages.field_required") },
							{
								validator: (rule, value: string) =>
									validateValueDifferentThan({
										value: parseTin(value),
										differentThan: representativesTins,
										error: t("dashboard.errors.company_tin_must_be_different_than_personal_tin"),
									}),
							},
						]}
						label={t("dashboard.signature.tin_for", {
							name: companyName,
						})}
						inputProps={{ placeholder: t("dashboard.signature.tin_placeholder") }}
					/>
				</Form>
			</Modal>
		</>
	);
};

export default ApplicationOverview;
