import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Checkbox, Form } from "antd";
import { Typography } from "antd";

import { RootState } from "src/store/reducers/rootReducer";

const { Link } = Typography;

const TermsAndConditionsCheckboxes = () => {
	const { t } = useTranslation();
	const { terms_url, data_protection_url, is_partner_terms_required, display_name } = useSelector(
		(state: RootState) => state.partnerState
	);
	const { companyName } = useSelector((state: RootState) => state.configState);
	const { terms, privacy_policy } = useSelector(
		(state: RootState) => state.configState.urlList?.value
	) || { terms: undefined, privacy_policy: undefined, imprint: undefined };

	return (
		<>
			<Form.Item
				name="terms"
				valuePropName="checked"
				rules={[
					{
						validator: (_, value) =>
							value
								? Promise.resolve()
								: Promise.reject(
										t("application_process.account_creation.errors.terms_and_conditions_invalid")
								  ),
					},
				]}
			>
				<Checkbox data-cy="terms-checkbox">
					<Trans
						i18nKey={"application_process.account_creation.terms_and_conditions"}
						shouldUnescape={true}
					>
						{`I accept the `}
						<Link href={terms} target="_blank" rel="noopener noreferrer">
							general terms and conditions
						</Link>
						{` and `}
						<Link href={privacy_policy} target="_blank" rel="noopener noreferrer">
							data protection conditions
						</Link>
						{{ legalName: companyName?.legalName, displayName: companyName?.displayName }}
					</Trans>
				</Checkbox>
			</Form.Item>

			{is_partner_terms_required && (
				<Form.Item
					name="termsPartner"
					valuePropName="checked"
					rules={[
						{
							validator: (_, value) =>
								value
									? Promise.resolve()
									: Promise.reject(
											t("application_process.account_creation.errors.terms_and_conditions_invalid")
									  ),
						},
					]}
				>
					<Checkbox data-cy="terms-checkbox-partner">
						<Trans
							i18nKey={"application_process.account_creation.terms_and_conditions_partner"}
							shouldUnescape={true}
						>
							{`I accept the `}
							<Link href={terms_url} target="_blank" rel="noopener noreferrer">
								terms and conditions
							</Link>
							{` and `}
							<Link href={data_protection_url} target="_blank" rel="noopener noreferrer">
								data protection policy
							</Link>
							{` of `}
							{{ displayName: display_name }}
						</Trans>
					</Checkbox>
				</Form.Item>
			)}
		</>
	);
};

export default TermsAndConditionsCheckboxes;
