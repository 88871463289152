import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "core-js";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18n from "i18next";

import Routes from "src/Routes";

import { AppConfig } from "./AppConfig";
import UserPartnerConfig from "./UserPartnerConfig";
import "./global.css";
import store from "./store/Store";
import { initAxios } from "./utils/Axios";
import "./variables.css";

const App = () => {
	const [ready, setReady] = useState(false);

	useEffect(() => {
		dayjs.extend(utc);
		dayjs.extend(timezone);
		dayjs.extend(customParseFormat);

		initAxios(i18n.t);
		setReady(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Provider store={store}>
			{ready && (
				<BrowserRouter>
					<AppConfig>
						<UserPartnerConfig>
							<Routes />
						</UserPartnerConfig>
					</AppConfig>
				</BrowserRouter>
			)}
		</Provider>
	);
};

const rootElement = document.getElementById("root");

if (rootElement) {
	const root = createRoot(rootElement);
	root.render(<App />);
}
