import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getCountryList } from "@teylor-tools/utils/i18n/Countries";
import {
	Translations,
	TranslationsProvider as TranslationsProviderUI,
} from "@ui/translations/translations";

export const TranslationsProvider = ({ children }: { children: ReactNode }) => {
	const { t, i18n } = useTranslation();

	const translationsUI: Partial<Translations> = useMemo(
		() => ({
			add: t("common.button_label.add"),
			save: t("common.button_label.save"),
			yes: t("common.button_label.yes"),
			no: t("common.button_label.no"),
			select: t("common.select"),
			edit: t("common.button_label.edit"),
			delete: t("common.button_label.delete"),

			city: t("application_process.company_details.city"),
			cityPlaceholder: t("application_process.company_details.city_placeholder"),
			street: t("application_process.company_details.street"),
			streetPlaceholder: t("application_process.company_details.street_placeholder"),
			country: t("application_process.company_details.country"),
			countries: getCountryList(i18n.language),
			emailInputErrorMessage: t("application_process.account_creation.errors.email_invalid"),
			emailInputLabel: t("application_process.account_creation.email"),
			emailInputPlaceholder: t("application_process.account_creation.email_placeholder"),

			formErrors: {
				fieldRequired: t("application_process.error_messages.field_required"),
				fieldInvalid: t("common.errors.field_invalid"),
				// TODO: weird error with any return value coming from translations provider init
				// we are going to migrate to another way to use lib translations so no reason to dive into it for now
				/* eslint-disable @typescript-eslint/no-unsafe-return */
				fieldMustBeBetweenMinMaxNumbers: (min: number, max: number) =>
					t("common.input_field.validation.between_min_max_numbers", { min, max }),
				fieldMustBeBetweenMinMaxChars: (min: number, max: number) =>
					t("common.input_field.validation.between_min_max_chars", { min, max }),
				fieldMustBeBetweenMinMax: (min: number | string, max: number | string) =>
					t("common.input_field.validation.between_min_max", { min, max }),
				fieldMustBeMinChars: (min: number) =>
					t("common.input_field.validation.between_min_max_chars", { min }),
				/* eslint-enable @typescript-eslint/no-unsafe-return */
				passwordShort: t("authentication.errors.password_too_short"),
				passwordNotComplex: t("authentication.errors.password_not_complex"),
				invalidPhoneNumber: t("common.errors.invalid-phone-number"),
				fieldContainsInvalidCharacters: t("common.errors.field-contains-invalid-characters"),
				personalTinMustBeDifferentThanCompanyTin: t(
					"dashboard.errors.personal_tin_must_be_different_than_company_tin"
				),
			},

			postcode: t("application_process.company_details.post_code"),
			postcodePlaceholder: t("application_process.company_details.post_code_placeholder"),
			tinInvalid: t("application_process.company_details.errors.tax_number_invalid"),
			tinPlaceholder: t("application_process.company_details.tax_number_placeholder"),
			hrNumber: t("application_process.company_details.hr_number"),
			hrNumberPlaceholder: t("application_process.company_details.hr_number_placeholder"),
			houseNumber: t("application_process.company_details.house_number"),
			houseNumberPlaceholder: t("application_process.company_details.house_number_placeholder"),

			representativeCard: {
				address: t("dashboard.company_representatives.address"),
				addTin: t("dashboard.company_representatives.add_tin"),
				beneficialOwner: t("dashboard.company_representatives.beneficial_owner"),
				canSignAlone: t("dashboard.company_representatives.can_sign_alone"),
				dateOfBirth: t("dashboard.company_representatives.dob"),
				email: t("application_process.account_creation.email"),
				legalSignatory: t("dashboard.company_representatives.legal_signatory"),
				nationality: t("dashboard.company_representatives.nationality"),
				ownership: t("dashboard.company_representatives.ownership"),
				phone: t("dashboard.company_representatives.phone"),
				tin: t("dashboard.company_representatives.tin"),
				willingToSign: t("dashboard.company_representatives.willing_to_sign"),
				creditScore: t("dashboard.company_representatives.schufa_score"),
				lastUpdated: t("dashboard.company_representatives.last_updated"),
			},

			representativeModal: {
				addRepresentative: t("dashboard.overview.pending_items.add_representatives"),
				allFieldsRequired: t("application_process.error_messages.all_fields_required"),
				beneficialOwner: t("dashboard.company_representatives.beneficial_owner"),
				canSignAlone: t("dashboard.company_representatives.can_sign_alone"),
				dateOfBirth: t("dashboard.company_representatives.dob"),
				editRepresentative: t("dashboard.company_representatives.edit_representative"),
				firstName: t("dashboard.company_representatives.first_name"),
				houseNumber: t("application_process.company_details.house_number"),
				lastName: t("dashboard.company_representatives.last_name"),
				legalSignatory: t("dashboard.company_representatives.legal_signatory"),
				nationality: t("dashboard.company_representatives.nationality"),
				optional: t("application_process.company_details.optional"),
				percentageOwnership: t("dashboard.company_representatives.percentage_ownership"),
				phoneNumber: t("dashboard.company_representatives.phone_number"),
				representativeNotContacted: t(
					"dashboard.company_representatives.representative_not_contacted"
				),
				street: t("dashboard.company_representatives.street"),
				taxNumber: t("application_process.company_details.tax_number"),
				willingToSign: t("dashboard.company_representatives.willing_to_sign"),
			},

			companySelectFormItem: {
				label: t("application_process.company_name.company_name"),
				placeholder: t("application_process.company_name.company_name_placeholder"),
				errorCompanyNameInvalid: t(
					"application_process.company_details.errors.company_name_invalid"
				),
				errorCompanyNameNotFound: t("application_process.company_name.company_name_not_found_text"),
				errorCompanyNameNotFoundLinkText: t(
					"application_process.company_name.company_name_not_found_link"
				),
			},

			object: {
				objects: t("dashboard.objects.objects"),
				object: t("dashboard.objects.object"),
				addObject: t("dashboard.objects.addObject"),
				editObject: t("dashboard.objects.editObject"),
				manufacturer: t("dashboard.objects.manufacturer"),
				type: t("dashboard.objects.type"),
				yearBuilt: t("dashboard.objects.yearBuilt"),
				objectDescription: t("dashboard.objects.detailedDesc"),
				netPurchasePrice: t("dashboard.objects.netPurchasePrice"),
				downPayment: t("dashboard.objects.downPayment"),
				remainingValue: t("dashboard.objects.remainingValue"),
				numberOfObjects: t("dashboard.objects.numberOfObjects"),
				financingType: t("dashboard.objects.financingType"),
				rentalState: t("dashboard.objects.rentalObject"),
				isFixedInterest: t("dashboard.objects.fixedInterest"),
				companyName: t("dashboard.objects.companyName"),
				street: t("dashboard.objects.street"),
				houseNumber: t("dashboard.objects.houseNumber"),
				city: t("dashboard.objects.city"),
				postcode: t("dashboard.objects.postcode"),
				country: t("dashboard.objects.country"),
				noObjectsHeader1: t("dashboard.objects.noObjects1"),
				noObjectsHeader2: t("dashboard.objects.noObjects2"),
				deleteModalText: t("dashboard.objects.deleteObjectModalTxt"),
				maxObjectsTooltip: t("dashboard.objects.maxObjectsTooltip"),
				retailerCompanyId: t("dashboard.objects.retailer-company-id"),
				retailerName: t("dashboard.objects.retailer-name"),
				retailerAddress: t("dashboard.objects.retailer-address"),
				expectedDeliveryDate: t("dashboard.objects.expected-delivery-date"),

				createOrderForm: "",

				errors: {
					required: t("application_process.error_messages.field_required"),
					postcodeInvalid: t("common.errors.field_invalid"),
				},

				placeholders: {
					manufacturer: t("dashboard.objects.placeholders.manufacturer"),
					type: t("dashboard.objects.placeholders.type"),
					yearBuilt: t("dashboard.objects.placeholders.yearBuilt"),
					objectDescription: t("dashboard.objects.placeholders.objectDescription"),
					objectCompanyName: t("dashboard.objects.placeholders.objectCompanyName"),
					street: t("dashboard.objects.placeholders.street"),
					houseNumber: t("dashboard.objects.placeholders.houseNumber"),
					city: t("dashboard.objects.placeholders.city"),
					retailerCompanyId: t("dashboard.objects.placeholders.retailer-company-id"),
				},

				options: {
					isFixedInterest: {
						yes: t("common.button_label.yes"),
						no: t("common.button_label.no"),
					},
					financingTypes: {
						leasing: t("dashboard.objects.financingType.leasing"),
						hire_purchase: t("dashboard.objects.financingType.hire_purchase"),
						financing: t("dashboard.objects.financingType.financing"),
						sale_leaseback: t("dashboard.objects.financingType.sale_leaseback"),
						credit_line: t("dashboard.objects.financingType.credit_line"),
						other: t("dashboard.objects.financingType.other"),
					},
					rentalStates: {
						no: t("dashboard.objects.rentalState.no"),
						internal: t("dashboard.objects.rentalState.internal"),
						b2c: t("dashboard.objects.rentalState.b2c"),
					},
				},

				sections: {
					retailer: t("dashboard.objects.retailer"),
				},
			},
		}),
		[t, i18n.language]
	);

	return <TranslationsProviderUI translations={translationsUI}>{children}</TranslationsProviderUI>;
};
