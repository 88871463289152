import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";

import { User } from "@teylor-tools/Api";
import Link from "@ui/Link";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";

import { RoutePaths } from "src/Routes";
import TermsAndConditionsCheckboxes from "src/components/form/TermsAndConditionsCheckboxes";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { loginUser } from "src/utils/api/authentication";

import { Button } from "./style/AuthenticationComponents";

const { Title, Text } = Typography;

interface RegistrationInput {
	email: string;
	password: string;
	terms: boolean;
	termsPartner: boolean;
}

const Register = () => {
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [form] = Form.useForm();

	usePageTitle(t("common.page_title.register"));

	const onFinish = ({ email: emailAddress, password, terms, termsPartner }: RegistrationInput) => {
		setLoading(true);
		const email = emailAddress.toLowerCase();
		Axios.post<User.UsersCreate.RequestBody, User.UsersCreate.ResponseBody>("/user/users", {
			email,
			password,
			accepted_terms: terms,
			accepted_partner_terms: termsPartner,
			interface_language: i18n.language,
			communication_language: i18n.language,
		})
			.then(
				() => {
					loginUser(email, password).then(
						() => {
							navigate(RoutePaths.dashboardApplications);
						},
						(error: ErrorResponse) => {
							void Axios.error(error, t("authentication.errors.login_wrong_email_or_password"));
						}
					);
				},
				(error: ErrorResponse) => {
					void Axios.error(
						error,
						t("application_process.account_creation.errors.registration_error")
					);
				}
			)
			.finally(() => setLoading(false));
	};

	return (
		<>
			<ContentCard>
				<Space direction="vertical" size={24}>
					<Title level={3}>{t("common.page_title.register")}</Title>
					<Form form={form} layout="vertical" onFinish={onFinish}>
						<EmailFormItem
							rules={[
								{
									required: true,
									message: t("application_process.error_messages.field_required"),
								},
							]}
						/>
						<PasswordFormItem
							name="password"
							label={t("application_process.account_creation.password")}
						/>
						<PasswordFormItem
							name="passwordRepeat"
							label={t("application_process.account_creation.password_repeat")}
							rules={[
								{
									required: true,
									message: t("application_process.error_messages.field_required"),
								},
								{
									validator: (_, passwordRepeat) =>
										passwordRepeat === form.getFieldValue("password")
											? Promise.resolve()
											: Promise.reject(
													t("application_process.account_creation.errors.password_repeat_invalid")
											  ),
								},
							]}
						/>
						<TermsAndConditionsCheckboxes />
						<Row>
							<Col span={24} style={{ textAlign: "center" }}>
								<Button type="primary" size="large" htmlType="submit" loading={loading}>
									{t("common.page_title.register")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Space>
			</ContentCard>
			<Space direction="vertical" size={4} align="center" style={{ width: "100%", marginTop: 40 }}>
				<Text style={{ textAlign: "center" }}>
					{t("application_process.account_creation.login_link_1")}
				</Text>
				<Link to={RoutePaths.login}>{t("authentication.login.click_to_login")}</Link>
			</Space>
		</>
	);
};

export default Register;
