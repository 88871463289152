import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import resources from "./translations.json";

const i18nInit = ({
	languages,
	defaultLanguage,
}: {
	languages?: string[];
	defaultLanguage?: string;
}) => {
	// detects browser language without locale
	const browserLangDetector = {
		name: "browser-lang-detector",
		lookup: () => {
			const browserLanguage: string = navigator.language.split("-")[0];

			return browserLanguage && languages?.includes(browserLanguage)
				? browserLanguage
				: defaultLanguage;
		},
	};

	const langDetector = new LanguageDetector();
	langDetector.addDetector(browserLangDetector);

	void i18n
		.use(initReactI18next)
		.use(langDetector)
		.init({
			detection: {
				order: ["querystring", "cookie", "browser-lang-detector"],
				lookupCookie: "i18next",
				caches: ["cookie"],
			},
			resources,
			defaultNS: "translation",
			fallbackLng: "de",
		});
};

export default i18nInit;
