import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";

import { User } from "@teylor-tools/Api";
import Link from "@ui/Link";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";

import { RoutePaths } from "src/Routes";
import TermsAndConditionsCheckboxes from "src/components/form/TermsAndConditionsCheckboxes";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import ApplicationFormBackButton from "src/pages/application-form/components/ApplicationFormBackButton";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { loginUser } from "src/utils/api/authentication";

const { Title, Text } = Typography;

interface RegistrationInput {
	email: string;
	password: string;
	terms: boolean;
	termsPartner: boolean;
}

const ApplicationAccountCreation = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	usePageTitle(t("common.page_title.new_application"));

	const onFinish = ({ email: emailAddress, password, terms, termsPartner }: RegistrationInput) => {
		const email = emailAddress.toLowerCase();
		Axios.post<User.UsersCreate.RequestBody, User.UsersCreate.ResponseBody>("/user/users", {
			email,
			password,
			accepted_terms: terms,
			accepted_partner_terms: termsPartner,
			interface_language: i18n.language,
			communication_language: i18n.language,
		}).then(
			() => void loginUser(email, password).then(() => navigate(RoutePaths.applicationResult)),
			(error: ErrorResponse) => {
				void Axios.error(
					error,
					t("application_process.account_creation.errors.registration_error")
				);
			}
		);
	};

	return (
		<>
			<ApplicationSteps stepIndex={3} />
			<ContentWrapper>
				<ApplicationFormCard>
					<ApplicationFormBackButton path={RoutePaths.applicationCompanyDetails} />
					<Space direction="vertical" size={24}>
						<Title level={3}>
							{t("application_process.account_creation.account_creation_title")}
						</Title>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<EmailFormItem
								rules={[
									{
										required: true,
										message: t("application_process.error_messages.field_required"),
									},
								]}
							/>
							<PasswordFormItem
								name="password"
								label={t("application_process.account_creation.password")}
							/>
							<PasswordFormItem
								name="passwordRepeat"
								label={t("application_process.account_creation.password_repeat")}
								rules={[
									{
										required: true,
										message: t("application_process.error_messages.field_required"),
									},
									{
										validator: (_, passwordRepeat) =>
											passwordRepeat === form.getFieldValue("password")
												? Promise.resolve()
												: Promise.reject(
														t("application_process.account_creation.errors.password_repeat_invalid")
												  ),
									},
								]}
							/>
							<TermsAndConditionsCheckboxes />
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton
										type="primary"
										size="large"
										htmlType="submit"
										data-cy="account-creation-button"
									>
										{t("common.button_label.next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
					<Text style={{ textAlign: "center", marginTop: 48 }}>
						{t("application_process.account_creation.login_link_1")}{" "}
						<Link
							to={{
								pathname: RoutePaths.login,
								search: "application=true",
							}}
							data-cy="login-user"
						>
							{t("application_process.account_creation.login_link_2")}
						</Link>
					</Text>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationAccountCreation;
