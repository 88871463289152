import { message } from "antd";
import { AxiosError } from "axios";
import { Namespace, TFunction } from "i18next";

import { ErrorCode } from "@teylor-tools/Api";
import { AxiosSingleton } from "@teylor-tools/utils/constructors/AxiosConstructor";

import store from "src/store/Store";
import { UserActions } from "src/store/actions/userActions";

export type { ErrorResponse } from "@teylor-tools/utils/constructors/AxiosConstructor";

export let Axios: AxiosSingleton;
export const initAxios = (t: TFunction<Namespace>) => {
	const beErrorString = (code: ErrorCode) => t(`common.errors.${code}`);
	let sessionExpiredShown = false;

	Axios = AxiosSingleton.Instance({
		apiPath: env.API_URL,
		t,
		message: (text: string, duration?: number) => {
			void message.error(text, duration);
		},
		// had to pass it here to keep typescript types inside of the project scope
		beErrorString,
	});

	Axios.axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error: AxiosError) {
			if (!error) {
				return Promise.reject();
			}

			if (error.response?.status === 401) {
				const responseURL = (error["request"] as { responseURL: string }).responseURL;

				const isUnauthedEndpoint = [
					"/account/login",
					"/account/register",
					"/account/recover",
					"/account/invite_accept",
					"/account/confirm_email",
				].some((path) => responseURL.includes(path));

				if (!isUnauthedEndpoint) {
					store.dispatch({ type: UserActions.LOGOUT });
					if (!sessionExpiredShown) {
						sessionExpiredShown = true;
						void message
							.error(t("authentication.errors.logged_out"))
							.then(() => (sessionExpiredShown = false));
					}
				}
			}

			return Promise.reject(error);
		}
	);
};
