import { FC } from "react";
import { useTranslation } from "react-i18next";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal as AntdModal, ModalProps, Space, Typography } from "antd";
import styled from "styled-components";

import { SignatoryWithId } from "@teylor-tools/Api";

const Modal = styled(AntdModal)`
	.ant-modal-body {
		padding: 24px 32px;
	}

	.ant-modal-footer {
		border-top: none;
		padding: 24px 32px 24px 0;
	}
`;

const QuestionCircleOutlinedIcon = styled(QuestionCircleOutlined)`
	color: ${({ theme }) => theme.colorPrimary};
	margin-top: 2px;
	font-size: 22px;
`;

const RepresentativesWrapper = styled.div`
	padding: 16px;
	border: 1px solid rgba(0, 0, 0, 0.3);
`;

interface Props {
	representatives: SignatoryWithId[];
	modalProps?: ModalProps;
}

const SubmitConfirmationModal: FC<Props> = ({ representatives, modalProps }: Props) => {
	const { t } = useTranslation();

	return (
		<Modal
			{...modalProps}
			okText={t("dashboard.general.submit_application_confirm")}
			cancelText={t("dashboard.general.no_adjust_representatives")}
			destroyOnClose
			closable={false}
			width={540}
		>
			<Space align="start">
				<QuestionCircleOutlinedIcon />
				<div>
					<Typography.Title level={5} data-cy="submit-application-modal" style={{ marginTop: 0 }}>
						{t("dashboard.general.all_representatives_added")}
					</Typography.Title>

					<Space direction="vertical" size={20} style={{ width: "100%" }}>
						<Typography.Text>
							{t("dashboard.general.before_submit_confirm_signatory")}
						</Typography.Text>
						<RepresentativesWrapper>
							<Typography.Text>
								{t("dashboard.general.company_representatives_list")}
							</Typography.Text>
							<ul style={{ padding: "10px 20px", fontWeight: "bold" }}>
								{representatives.map((representative) => (
									<li key={representative.signatory_id}>
										{representative.first_name} {representative.last_name}
									</li>
								))}
							</ul>
						</RepresentativesWrapper>
					</Space>
				</div>
			</Space>
		</Modal>
	);
};

export default SubmitConfirmationModal;
