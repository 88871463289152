/*
 * Breakpoints in accordance with antd
 */

export const xsScreen = 480;
export const smScreen = 576;
export const mdScreen = 768;
export const lgScreen = 992;
export const xlScreen = 1200;
export const xxlScreen = 1600;

export const maxWidth = (screen: number) => `${screen - 1}px`;

export const minWidth = (screen: number) => `${screen}px`;
