import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { VideoCameraOutlined } from "@ant-design/icons";
import { Space as AntdSpace, Tag as AntdTag, Button, Card, Col, Row, Typography } from "antd";
import Link from "antd/es/typography/Link";
import dayjs from "dayjs";
import styled from "styled-components";

import {
	SignatoryWithId,
	SignatureProvider,
	SignatureUserFacingStatus,
	User,
} from "@teylor-tools/Api";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";

import IdNowLogo from "src/assets/idnow.png";
import PostIdentLogo from "src/assets/postident.png";
import SwissSignLogo from "src/assets/swisssign.png";
import Alert from "src/components/Alert";
import { Axios, ErrorResponse } from "src/utils/Axios";

import { CheckCircleIcon } from "./SignatureTab";

const { Text } = Typography;

const Tag = styled(AntdTag)`
	border-radius: 50px;
	margin-left: 16px;
`;

const Space = styled(AntdSpace)`
	width: 100%;
	white-space: pre-line;
`;

interface StepContent {
	title: string;

	content1: JSX.Element;
	content2: JSX.Element;
	ctaText: string;
	ctaOnClick: () => void;
	logo: JSX.Element;

	inProgressTag: string;
	inProgressInfo: string | JSX.Element;

	failedTag: string;
	failedInfo: string | JSX.Element;
	failedCtaText: string;
}

let interval: ReturnType<typeof setInterval>;

interface Props {
	completedIdentStep?: boolean;
	isLoanSignatureRequired?: boolean;
	isSigned?: boolean;
	stepIndex: number;
	signatory: SignatoryWithId;
	checkSteps: () => void;
}

const SignatureTabIdentStep = ({
	completedIdentStep,
	isLoanSignatureRequired,
	isSigned,
	stepIndex,
	signatory,
	checkSteps,
}: Props) => {
	const { t } = useTranslation();
	const [loanContractUrl, setLoanContractUrl] = useState<string>();
	const [btnLoading, setBtnLoading] = useState(false);
	const [status, setStatus] = useState(signatory.requirements?.signature_status);

	const startIDNowProcess = () => {
		window.open(signatory.requirements?.digital_signature_url, "_blank");
		checkIdentStatusAtIntervals();
	};

	const startSwissSignProcess = () => {
		setBtnLoading(true);

		void Axios.post<
			User.SignatoriesRequirementsVideoIdentsCreate.RequestBody,
			User.SignatoriesRequirementsVideoIdentsCreate.ResponseBody
		>(`/user/signatories_requirements/${signatory.signatory_id}/video_idents`)
			.then(() => checkIdentStatusAtIntervals())
			.catch((err: ErrorResponse) =>
				Axios.error(err, t("dashboard.general.errors.something_went_wrong"))
			)
			.finally(() => setBtnLoading(false));
	};

	const startPostIdentProcess = () => {
		const digitalSignatureURL = signatory.requirements?.digital_signature_url;
		if (!digitalSignatureURL) return;

		const url = new URL(digitalSignatureURL);
		const params = new URLSearchParams(url.search);
		const authToken = params.get("authToken");

		void Axios.post(`${url.origin}${url.pathname}`, { authToken })
			.then((res) => {
				const htmlContent = res.data as string;
				const newWindow = window.open();
				newWindow?.document.write(htmlContent);
			})
			.catch((err: ErrorResponse) => Axios.error(err));
	};

	const stepContent = useMemo((): StepContent | undefined => {
		const isIdNow = signatory.requirements?.signature_provider === SignatureProvider.Idnow;
		const isSwissSign = signatory.requirements?.signature_provider === SignatureProvider.SwissSign;
		const isPostIdent = signatory.requirements?.signature_provider === SignatureProvider.Postident;

		if (isIdNow) {
			return {
				...(isLoanSignatureRequired
					? {
							title: t("dashboard.signature.idnow_title"),
							content1: (
								<Trans i18nKey="dashboard.signature.idnow_content_1">
									Follow these steps to identify yourself and digitally sign
									<Link href={loanContractUrl} download>
										your loan contract
									</Link>
								</Trans>
							),
							content2: <Text>{t("dashboard.signature.idnow_content_2")}</Text>,
							ctaText: t("dashboard.signature.idnow_cta"),
							failedCtaText: t("dashboard.signature.idnow_restart_cta"),
					  }
					: {
							title: t("dashboard.signature.idnow_ident_only_title"),
							content1: <Text>{t("dashboard.signature.idnow_ident_only_content_1")}</Text>,
							content2: <Text>{t("dashboard.signature.idnow_ident_only_content_2")}</Text>,
							ctaText: t("dashboard.signature.idnow_ident_only_cta"),
							failedCtaText: t("dashboard.signature.idnow_ident_only_restart_cta"),
					  }),
				ctaOnClick: () => startIDNowProcess(),
				logo: <img src={IdNowLogo} alt="IDNow Logo" height={24} />,
				inProgressTag: t("dashboard.signature.idnow_in_progress_tag"),
				inProgressInfo: t("dashboard.signature.idnow_in_progress_info"),
				failedTag: t("dashboard.signature.idnow_failed_tag"),
				failedInfo: t("dashboard.signature.idnow_failed"),
			};
		}

		if (isSwissSign) {
			return {
				...(isLoanSignatureRequired
					? {
							title: t("dashboard.signature.swisssign_title"),
							content1: (
								<Trans i18nKey="dashboard.signature.swisssign_content_1">
									Follow these steps to identify yourself and digitally sign
									<Link href={loanContractUrl} download>
										your loan contract
									</Link>
								</Trans>
							),
							content2: <Text>{t("dashboard.signature.swisssign_content_2")}</Text>,
							ctaText: t("dashboard.signature.swisssign_cta"),
							failedCtaText: t("dashboard.signature.swisssign_restart_cta"),
					  }
					: {
							title: t("dashboard.signature.swisssign_ident_only_title"),

							content1: <Text>{t("dashboard.signature.swisssign_ident_only_content_1")}</Text>,
							content2: <Text>{t("dashboard.signature.swisssign_ident_only_content_2")}</Text>,
							ctaText: t("dashboard.signature.swisssign_ident_only_cta"),
							failedCtaText: t("dashboard.signature.idnow_restart_cta"),
					  }),
				ctaOnClick: () => startSwissSignProcess(),
				logo: <img src={SwissSignLogo} alt="Swiss Sign Logo" height={24} />,
				inProgressTag: t("dashboard.signature.swisssign_in_progress_tag"),
				inProgressInfo: (
					<Trans
						i18nKey="dashboard.signature.swisssign_in_progress_info"
						values={{ email: signatory.email }}
					>
						To proceed please check your inbox for an email from SwissSign and follow the
						instructions. The email was sent to <strong>email</strong>
					</Trans>
				),
				failedTag: t("dashboard.signature.swisssign_failed_tag"),
				failedInfo: t("dashboard.signature.swisssign_failed"),
				failedCtaText: t("dashboard.signature.swisssign_ident_only_restart_cta"),
			};
		}

		if (isPostIdent) {
			return {
				title: t("dashboard.signature.postident_title"),
				content1: (
					<Trans i18nKey="dashboard.signature.postident_content_1">
						Follow these steps to identify yourself and digitally sign
						<Link href={loanContractUrl} download>
							your loan contract
						</Link>
					</Trans>
				),
				content2: <Text>{t("dashboard.signature.postident_content_2")}</Text>,
				ctaText: t("dashboard.signature.postident_cta"),
				failedCtaText: t("dashboard.signature.postident_restart_cta"),
				ctaOnClick: () => startPostIdentProcess(),
				logo: <img src={PostIdentLogo} alt="Postident Logo" height={24} />,
				inProgressTag: t("dashboard.signature.postident_in_progress_tag"),
				inProgressInfo: (
					<Trans
						i18nKey="dashboard.signature.postident_in_progress_info"
						values={{ email: signatory.email }}
					>
						To proceed please check your inbox for an email from SwissSign and follow the
						instructions. The email was sent to <strong>email</strong>
					</Trans>
				),
				failedTag: t("dashboard.signature.postident_failed_tag"),
				failedInfo: t("dashboard.signature.postident_failed"),
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, signatory.requirements?.signature_provider, isLoanSignatureRequired, loanContractUrl]);

	const checkIdentStatusAtIntervals = () => {
		Axios.get<
			User.SignatoriesRequirementsDetail.RequestQuery,
			User.SignatoriesRequirementsDetail.ResponseBody
		>(`user/signatories/${signatory.signatory_id}/requirements`)
			.then(({ data }) => {
				if (data.signature_status !== status) {
					setStatus(data.signature_status);
				}

				if (data.signature_status === SignatureUserFacingStatus.Completed) {
					clearInterval(interval);
					checkSteps();
				}

				return Promise.resolve(data);
			})
			.catch(() => clearInterval(interval));

		if (!interval) {
			interval = setInterval(checkIdentStatusAtIntervals, 10 * 1000);
		}
	};

	useEffect(() => {
		Axios.get<
			User.ApplicationsLoanContractDetail.RequestQuery,
			User.ApplicationsLoanContractDetail.ResponseBody
		>(`/user/applications/${signatory.application_id}/loan_contract`).then(
			(response) => setLoanContractUrl(response.data.download_url),
			(err: ErrorResponse) => Axios.error(err, t("dashboard.general.errors.something_went_wrong"))
		);

		if (status === SignatureUserFacingStatus.InProgress) {
			checkIdentStatusAtIntervals();
		}

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (
		signatory.requirements?.signature_provider === SignatureProvider.Manual &&
		status === SignatureUserFacingStatus.Completed
	) {
		return (
			<Col span={24}>
				<Card
					title={
						<Row align="middle">
							{completedIdentStep && <CheckCircleIcon />}
							<Text>
								{!isSigned && `${stepIndex + 1}. `}
								{t("dashboard.signature.idnow_title")}
							</Text>
						</Row>
					}
				>
					<Space direction="vertical" size={24}>
						<Text type="secondary">
							<Trans
								i18nKey="dashboard.signature.completed_and_signed_on"
								values={{
									date: dayjs(signatory.requirements?.signature_completed_at).format(yearMonthDay),
								}}
							>
								Completed and signed on <strong>date</strong>
							</Trans>
						</Text>
					</Space>
				</Card>
			</Col>
		);
	}

	if (!stepContent) {
		return (
			<Col span={24}>
				<Card
					title={
						<Text>
							{!isSigned && `${stepIndex + 1}. `}
							{t("dashboard.signature.provider_error_title")}
						</Text>
					}
				>
					<Text>{t("dashboard.signature.provider_error_content")}</Text>
				</Card>
			</Col>
		);
	}

	return (
		<Col span={24}>
			<Card
				title={
					<Row align="middle">
						{completedIdentStep && <CheckCircleIcon />}
						<Text>
							{!isSigned && `${stepIndex + 1}. `}
							{stepContent.title}
						</Text>
					</Row>
				}
			>
				<Space direction="vertical" size={24}>
					{status === SignatureUserFacingStatus.NotCompleted && (
						<>
							{stepContent.content1}
							{stepContent.content2}

							<Row justify="space-between">
								<Button
									type="primary"
									icon={<VideoCameraOutlined />}
									onClick={stepContent.ctaOnClick}
									loading={btnLoading}
								>
									{stepContent.ctaText}
								</Button>

								{stepContent.logo}
							</Row>
						</>
					)}

					{status === SignatureUserFacingStatus.InProgress && (
						<>
							<Row>
								<Text type="secondary">
									<strong>{t("dashboard.signature.status")}:</strong>
								</Text>
								<Tag>{stepContent.inProgressTag}</Tag>
							</Row>

							<Alert type="info" showIcon message={stepContent.inProgressInfo} />
						</>
					)}

					{status === SignatureUserFacingStatus.Completed && (
						<Text type="secondary">
							<Trans
								i18nKey="dashboard.signature.completed_and_signed_on"
								values={{
									date: dayjs(signatory.requirements?.signature_completed_at).format(yearMonthDay),
								}}
							>
								Completed and signed on <strong>date</strong>
							</Trans>
						</Text>
					)}

					{status === SignatureUserFacingStatus.Failed && (
						<>
							<Row>
								<Text type="secondary">
									<strong>{t("dashboard.signature.status")}:</strong>
								</Text>
								<Tag color="error">{stepContent.failedTag}</Tag>
							</Row>

							<Alert type="info" showIcon message={stepContent.failedInfo} />

							<Row justify="space-between">
								<Button
									type="primary"
									icon={<VideoCameraOutlined />}
									onClick={stepContent.ctaOnClick}
									loading={btnLoading}
								>
									{stepContent.failedCtaText}
								</Button>

								{stepContent.logo}
							</Row>
						</>
					)}
				</Space>
			</Card>
		</Col>
	);
};

export default SignatureTabIdentStep;
