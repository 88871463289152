import { FC } from "react";
import { useSelector } from "react-redux";
import {
	Navigate,
	Outlet,
	Path,
	Route,
	Routes as RouterRoutes,
	useLocation,
} from "react-router-dom";

import NotFoundPage from "./pages/NotFoundPage";
import ApplicationAccountCreation from "./pages/application-form/ApplicationAccountCreation";
import ApplicationCompanyDetails from "./pages/application-form/ApplicationCompanyDetails";
import ApplicationCompanyName from "./pages/application-form/ApplicationCompanyName";
import ApplicationLoanInfo from "./pages/application-form/ApplicationLoanInfo";
import ApplicationResult from "./pages/application-form/ApplicationResult";
import ApplicationPageLayout from "./pages/application-form/components/ApplicationPageLayout";
import ConfirmEmail from "./pages/authentication/ConfirmEmail";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import RegisterInvitation from "./pages/authentication/RegisterInvitation";
import ResetPassword from "./pages/authentication/ResetPassword";
import AuthenticationPageLayout from "./pages/authentication/components/AuthenticationPageLayout";
import DashboardApplicationOverview from "./pages/dashboard/DashboardApplicationOverview";
import DashboardApplicationsList from "./pages/dashboard/DashboardApplicationsList";
import DashboardSettings from "./pages/dashboard/DashboardSettings";
import DashboardPageLayout from "./pages/dashboard/components/DashboardPageLayout";
import { ApplicationFormProvider } from "./providers/application-form-provider/useApplicationForm";
import { RootState } from "./store/reducers/rootReducer";

export enum RoutePaths {
	index = "/",

	applicationLoanInfo = "/application/loan-info",
	OLDApplicationLoanInfo = "/loan-info",
	applicationCompanyName = "/application/company-name",
	applicationCompanyDetails = "/application/company-details",
	applicationAccountCreation = "/application/account-creation",
	applicationResult = "/application/application-result",

	login = "/login",
	signin = "/signin",
	forgotPassword = "/forgot-password",
	resetPassword = "/user/account/reset/:token",
	register = "/register",
	OLDRegister = "/signup",
	registerInvitation = "/user/account/invite/:token",
	confirmEmail = "/user/account/confirm_email/:confirmationCode",

	dashboard = "/dashboard",
	dashboardApplications = "/dashboard/applications",
	dashboardApplicationOverview = "/dashboard/applications/:id",
	dashboardSettings = "/dashboard/settings",

	notFound = "/not-found",
}

type RouteType = typeof RoutePaths[keyof typeof RoutePaths];

interface RouteFullPath extends Omit<Path, "pathname"> {
	pathname: RouteType;
}

// This type is made to match our routes in useNavigate hook
export type RoutePath = RouteType | Partial<RouteFullPath>;

export interface LocationState {
	urlToRedirect?: string;
}

const ProtectedRoute: FC = () => {
	const { isAuthenticated, loggedOutByUser } = useSelector((state: RootState) => state.userState);
	const location = useLocation();

	if (!isAuthenticated) {
		const state: LocationState = loggedOutByUser ? {} : { urlToRedirect: location.pathname };
		return <Navigate to={RoutePaths.login} replace state={state} />;
	}

	return <Outlet />;
};

const AuthRoutes: FC = () => {
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const { userCreateApplication } = useSelector((state: RootState) => state.configState);

	if (isAuthenticated) {
		if (!userCreateApplication) {
			return <Navigate to={RoutePaths.dashboardApplications} replace />;
		}

		return <Navigate to={RoutePaths.index} replace />;
	}

	return <Outlet />;
};

const ApplicationCreationRoutes: FC = () => {
	const { userCreateApplication } = useSelector((state: RootState) => state.configState);

	if (!userCreateApplication) {
		return <Navigate to={RoutePaths.login} replace />;
	}

	return <Outlet />;
};

const Routes = () => {
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const { userCreateApplication } = useSelector((state: RootState) => state.configState);
	return (
		<RouterRoutes>
			<Route element={<ApplicationFormProvider />}>
				<Route element={<ApplicationPageLayout />}>
					<Route
						path={RoutePaths.index}
						element={
							<Navigate
								to={
									isAuthenticated
										? RoutePaths.dashboardApplications
										: userCreateApplication
										? RoutePaths.applicationLoanInfo
										: RoutePaths.login
								}
								replace
							/>
						}
					/>
					<Route element={<ApplicationCreationRoutes />}>
						<Route path={RoutePaths.applicationLoanInfo} element={<ApplicationLoanInfo />} />
						<Route path={RoutePaths.applicationCompanyName} element={<ApplicationCompanyName />} />
						<Route
							path={RoutePaths.applicationCompanyDetails}
							element={<ApplicationCompanyDetails />}
						/>
					</Route>
				</Route>

				<Route element={<ProtectedRoute />}>
					<Route element={<DashboardPageLayout />}>
						<Route
							path={RoutePaths.dashboard}
							element={<Navigate to={RoutePaths.dashboardApplications} replace />}
						/>
						<Route
							path={RoutePaths.dashboardApplications}
							element={<DashboardApplicationsList />}
						/>
						<Route
							path={RoutePaths.dashboardApplicationOverview}
							element={<DashboardApplicationOverview />}
						/>
						<Route path={RoutePaths.dashboardSettings} element={<DashboardSettings />} />
					</Route>
					<Route element={<ApplicationPageLayout />}>
						<Route path={RoutePaths.applicationResult} element={<ApplicationResult />} />
					</Route>
				</Route>

				<Route element={<AuthRoutes />}>
					<Route element={<ApplicationPageLayout />}>
						<Route
							path={RoutePaths.applicationAccountCreation}
							element={<ApplicationAccountCreation />}
						/>
					</Route>
					<Route element={<AuthenticationPageLayout />}>
						<Route path={RoutePaths.login} element={<Login />} />
						<Route path={RoutePaths.forgotPassword} element={<ForgotPassword />} />
						<Route path={RoutePaths.resetPassword} element={<ResetPassword />} />
						<Route path={RoutePaths.register} element={<Register />} />
						<Route path={RoutePaths.registerInvitation} element={<RegisterInvitation />} />
					</Route>
				</Route>

				<Route element={isAuthenticated ? <DashboardPageLayout /> : <AuthenticationPageLayout />}>
					<Route path={RoutePaths.confirmEmail} element={<ConfirmEmail />} />
				</Route>
			</Route>

			<Route path={RoutePaths.signin} element={<Navigate to={RoutePaths.login} />} />
			<Route path={RoutePaths.OLDRegister} element={<Navigate to={RoutePaths.register} />} />
			<Route
				path={RoutePaths.OLDApplicationLoanInfo}
				element={<Navigate to={RoutePaths.applicationLoanInfo} />}
			/>
			<Route path="*" element={<NotFoundPage />} />
		</RouterRoutes>
	);
};

export default Routes;
