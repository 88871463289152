import { Button } from "antd";
import styled from "styled-components";

const ApplicationFormButton = styled(Button)`
	min-width: 185px;
	align-self: center;
	margin-top: 24px;
`;

export default ApplicationFormButton;
