import { UserState } from "src/store/reducers/userReducer";

export enum UserActions {
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	SET_PROFILE_LOADING = "SET_PROFILE_LOADING",
	SET_PROFILE = "SET_PROFILE",
	RESET_PROFILE = "RESET_PROFILE",
}

export type UserAction =
	| { type: UserActions.LOGIN }
	| { type: UserActions.LOGOUT; payload: { loggedOutByUser: boolean } }
	| { type: UserActions.SET_PROFILE_LOADING; payload: boolean }
	| { type: UserActions.SET_PROFILE; payload: Partial<UserState> }
	| { type: UserActions.RESET_PROFILE };

export const logout = (loggedOutByUser = false): UserAction => {
	return {
		type: UserActions.LOGOUT,
		payload: { loggedOutByUser },
	};
};

export const login = (): UserAction => {
	return {
		type: UserActions.LOGIN,
	};
};

export const setProfileLoading = (payload: boolean): UserAction => {
	return {
		type: UserActions.SET_PROFILE_LOADING,
		payload,
	};
};

export const setProfile = (payload: Partial<UserState>): UserAction => {
	return {
		type: UserActions.SET_PROFILE,
		payload,
	};
};

export const resetProfile = (): UserAction => {
	return {
		type: UserActions.RESET_PROFILE,
	};
};
