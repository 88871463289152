import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button as AntdButton } from "antd";
import styled from "styled-components";

import { RoutePath } from "src/Routes";

const Button = styled(AntdButton)`
	margin-bottom: 32px;
	padding: 0px;
	color: #00000073;
	align-self: flex-start;
`;

interface Props {
	path: RoutePath;
}

const ApplicationFormBackButton = ({ path }: Props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate(path)}>
			{t("common.button_label.back")}
		</Button>
	);
};

export default ApplicationFormBackButton;
