import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";

import Link from "@ui/Link";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";

import { RoutePaths } from "src/Routes";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import { Axios, ErrorResponse } from "src/utils/Axios";

import { Button, CheckCircleIcon } from "./style/AuthenticationComponents";

const { Title, Text } = Typography;

const ForgotPassword = () => {
	const { t } = useTranslation();
	const [resetSuccess, setResetSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [form] = Form.useForm();

	usePageTitle(t("common.page_title.forgotPassword"));

	const onFinish = ({ email }: { email: string }) => {
		setLoading(true);
		Axios.post("/user/account/reset", {
			email,
		})
			.then(
				() => {
					setResetSuccess(true);
				},
				(error: ErrorResponse) => {
					void Axios.error(error, t("authentication.errors.forgot_password_error"));
				}
			)
			.finally(() => setLoading(false));
	};

	if (resetSuccess) {
		return (
			<ContentCard>
				<div style={{ textAlign: "center" }}>
					<CheckCircleIcon />
				</div>
				<Title level={3} style={{ textAlign: "center" }}>
					{t("authentication.password_reset.password_reset_email_sent_title")}
				</Title>
				<Text type="secondary" style={{ textAlign: "center" }}>
					{t("authentication.password_reset.password_reset_email_sent_subtitle")}
				</Text>
				<Button
					type="primary"
					size="large"
					style={{ marginTop: 48 }}
					onClick={() => navigate(RoutePaths.login)}
					data-cy="go-to-login-button"
				>
					{t("authentication.login.go_to_login")}
				</Button>
			</ContentCard>
		);
	}

	return (
		<ContentCard>
			<Space direction="vertical" size={24}>
				<div>
					<Title level={3}>{t("authentication.forgot_password.forgot_password_title")}</Title>
					<Text type="secondary">
						{t("authentication.forgot_password.forgot_password_subtitle")}
					</Text>
				</div>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<EmailFormItem
						rules={[
							{
								required: true,
								message: t("application_process.error_messages.field_required"),
							},
						]}
					/>
					<Row>
						<Col span={24} style={{ textAlign: "center" }}>
							<Button
								type="primary"
								size="large"
								htmlType="submit"
								loading={loading}
								data-cy="forgot-password-button"
							>
								{t("authentication.password_reset.reset_password_button")}
							</Button>
						</Col>
					</Row>
				</Form>
			</Space>
			<Text style={{ textAlign: "center", marginTop: 16 }}>
				{t("authentication.login.go_to_login_link_1")}{" "}
				<Link to={RoutePaths.login}>{t("authentication.login.go_to_login_link_2")}</Link>
			</Text>
		</ContentCard>
	);
};

export default ForgotPassword;
