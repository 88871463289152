import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { generate } from "@ant-design/colors";
import { theme as AntdTheme } from "antd";
import { ConfigProvider } from "antd";
import { AliasToken } from "antd/es/theme/internal";
import styled, { DefaultTheme, ThemeProvider } from "styled-components";

import { EntitlementType, Partner } from "@teylor-tools/Api";
import { useTranslations } from "@ui/translations/translations";

import { RoutePaths } from "src/Routes";
import FullPageSpinner from "src/components/FullPageSpinner";
import { setCountryList } from "src/store/actions/configActions";
import { setPartner } from "src/store/actions/partnerActions";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios } from "src/utils/Axios";
import { getProfile } from "src/utils/api/authentication";

const AppGlobalStyle = styled.div`
	font-family: "Asap", sans-serif;
`;

const { useToken } = AntdTheme;

const UserPartnerConfig = ({ children }: { children: ReactElement }) => {
	const { countries } = useTranslations();
	const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
	const [theme, setTheme] = useState<DefaultTheme>();
	const [antdTheme, setAntdTheme] = useState<Partial<AliasToken>>();
	const dispatch = useDispatch();
	const { isAuthenticated } = useSelector((state: RootState) => state.userState);
	const { preferredCountries } = useSelector((state: RootState) => state.configState);
	const { token } = useToken();
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			void getProfile();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (themeLoaded) {
			dispatch(setCountryList({ preferredCountries, countries }));
		}
	}, [countries, dispatch, preferredCountries, themeLoaded]);

	useEffect(() => {
		if (themeLoaded) {
			return;
		}

		const subdomain = window.location.host.split(".")[0];

		Axios.get<Partner.KeyConfigDetail.RequestQuery, Partner.KeyConfigDetail.ResponseBody>(
			`/partner/key/${subdomain}/config`
		)
			.then(({ data }) => {
				if (!data.entitlements?.includes(EntitlementType.WhitelabeledUserBranding)) {
					setTheme({} as DefaultTheme);
					return Promise.reject("No entitlements");
				}

				if (Object.keys(data.fe_theme).length === 0) {
					setTheme({} as DefaultTheme);
					return Promise.reject("No theme provided");
				}

				if (
					data.fe_theme.color_link &&
					data.fe_theme.color_primary &&
					data.fe_theme.color_cta_text
				) {
					const themed = {
						// button type="primary"
						colorPrimary: data.fe_theme.color_primary,
						colorTextLightSolid: data.fe_theme.color_cta_text,

						// button type="link"
						colorLink: data.fe_theme.color_link,
						colorLinkHover: generate(data.fe_theme.color_link)[4],
						colorLinkActive: generate(data.fe_theme.color_link)[6],
					};

					setAntdTheme(themed);

					setTheme({
						...data.fe_theme,
						...token,
						...themed,
					});
				}

				dispatch(
					setPartner({
						configLoaded: true,
						customer_support_email: data.parameters.customer_support_email,
						customer_support_phone: data.parameters.customer_support_phone,
						display_name: data.parameters.display_name,
						legal_name: data.parameters.legal_name,
						terms_url: data.terms_url,
						data_protection_url: data.data_protection_url,
						is_partner_terms_required: data.is_partner_terms_required,
						userApplicationTypes: data.user_application_types || [],
						entitlements: data.entitlements || [],
					})
				);
			})
			.catch((err) => {
				console.error("Config error: ", err);
				navigate(RoutePaths.notFound);
			})
			.finally(() => setThemeLoaded(true));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [themeLoaded]);

	return (
		<AppGlobalStyle>
			<ConfigProvider
				theme={{
					token: {
						...antdTheme,
					},
				}}
			>
				{themeLoaded && theme ? (
					<ThemeProvider theme={theme}>{{ ...children }}</ThemeProvider>
				) : (
					<FullPageSpinner />
				)}
			</ConfigProvider>
		</AppGlobalStyle>
	);
};
export default UserPartnerConfig;
