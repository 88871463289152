import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Space, Spin, Tooltip, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import styled from "styled-components";

import { ApplicationStatuses, Currency, FinanceObjectGetResponse, User } from "@teylor-tools/Api";
import NoObjectsCard from "@ui/object-financing/NoObjectsCard";
import ObjectCard from "@ui/object-financing/ObjectCard";
import ObjectForm, {
	FinancingObjectForm,
	MAX_NUMBER_OF_OBJECTS,
} from "@ui/object-financing/ObjectForm";
import {
	prepareObjectFormFromResponse,
	prepareRequestObjectFromForm,
} from "@ui/object-financing/objects.utils";

import Alert from "src/components/Alert";
import Error from "src/components/Error";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const AddBtnWrapper = styled.div`
	display: flex;
	justify-content: end;
	margin-bottom: 32px;
`;

const ExclamationCircleOutlinedIcon = styled(ExclamationCircleOutlined)`
	color: ${({ theme }) => theme.colorError};
	margin-top: 2px;
	font-size: 22px;
`;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	margin-top: calc(50vh - 200px);
`;

interface Props {
	applicationStatus: ApplicationStatuses;
	applicationId: string;
	onUpdate: () => void;
	currency: Currency;
}

const OBJECT_FORM_EDIT = "object-form-edit";
const OBJECT_FORM_ADD = "object-form-add";

const ObjectsTab: FC<Props> = ({ applicationStatus, applicationId, onUpdate, currency }: Props) => {
	const { t } = useTranslation();
	const [objects, setObjects] = useState<FinanceObjectGetResponse[]>([]);
	const [formEdit] = useForm<FinancingObjectForm>();
	const [formAdd] = useForm<FinancingObjectForm>();
	const [objectToDelete, setObjectToDelete] = useState<FinanceObjectGetResponse>();
	const [showAddModal, setShowAddModal] = useState(false);
	const [isModalSendingData, setIsModalSendingData] = useState(false);
	const [objectToEdit, setObjectToEdit] = useState<FinanceObjectGetResponse>();
	const [error, setError] = useState<ErrorResponse>();
	const [loading, setLoading] = useState(false);
	const { countries } = useSelector((state: RootState) => state.configState);

	const isEditable = [ApplicationStatuses.Open, ApplicationStatuses.PreCheck].includes(
		applicationStatus
	);

	const defaultCountry = useMemo(() => countries[0]?.code, [countries]);

	const isAddObjectBtnDisabled = objects.length >= MAX_NUMBER_OF_OBJECTS;

	const getObjects = useCallback(() => {
		setLoading(true);
		void Axios.get<
			User.ApplicationsFinanceObjectsDetail.RequestQuery,
			User.ApplicationsFinanceObjectsDetail.ResponseBody
		>(`/user/applications/${applicationId}/finance_objects`)
			.then(({ data }) => setObjects(data))
			.catch((err: ErrorResponse) => setError(err))
			.finally(() => setLoading(false));
	}, [applicationId]);

	const getObjectIndex = (objToFind: FinanceObjectGetResponse) =>
		objects.findIndex((obj) => obj.finance_object_id === objToFind.finance_object_id);

	const onEdit = (obj: FinanceObjectGetResponse) => {
		setObjectToEdit(obj);
		formEdit.setFieldsValue(prepareObjectFormFromResponse(obj));
	};

	const deleteObject = () => {
		if (!objectToDelete?.finance_object_id) return;

		void Axios.delete(
			`/user/applications/${applicationId}/finance_objects/${objectToDelete.finance_object_id}`
		)
			.then(() => {
				void message.success(t("dashboard.objects.success.objectDeleteSuccess"));
				getObjects();
				onUpdate();
			})
			.catch((err: ErrorResponse) =>
				Axios.error(err, t("dashboard.objects.errors.objectDeleteError"))
			)
			.finally(() => setObjectToDelete(undefined));
	};

	const editObject = (form: FinancingObjectForm) => {
		if (!objectToEdit?.finance_object_id) {
			return;
		}

		setIsModalSendingData(true);

		void Axios.put<
			User.ApplicationsFinanceObjectsUpdate.RequestBody,
			User.ApplicationsFinanceObjectsUpdate.ResponseBody
		>(
			`/user/applications/${applicationId}/finance_objects/${objectToEdit?.finance_object_id}`,
			prepareRequestObjectFromForm(form)
		)
			.then(() => {
				void message.success(t(`dashboard.objects.success.objectEditSuccess`));
				getObjects();
				setObjectToEdit(undefined);
				onUpdate();
			})
			.catch((err: ErrorResponse) =>
				Axios.error(err, t("dashboard.objects.errors.objectEditError"))
			)
			.finally(() => setIsModalSendingData(false));
	};

	const addObject = (form: FinancingObjectForm) => {
		setIsModalSendingData(true);

		void Axios.post<
			User.ApplicationsFinanceObjectsCreate.RequestBody,
			User.ApplicationsFinanceObjectsCreate.ResponseBody
		>(`/user/applications/${applicationId}/finance_objects`, prepareRequestObjectFromForm(form))
			.then(() => {
				void message.success(t("dashboard.objects.success.objectAddSuccess"));
				setShowAddModal(false);
				getObjects();
				onUpdate();
			})
			.catch((err: ErrorResponse) => Axios.error(err, t("dashboard.objects.errors.objectAddError")))
			.finally(() => setIsModalSendingData(false));
	};

	useEffect(getObjects, [getObjects]);

	if (error) {
		return <Error error={error} />;
	}

	if (loading) {
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);
	}

	return (
		<>
			{isEditable && !!objects.length && (
				<AddBtnWrapper>
					<Tooltip
						title={
							isAddObjectBtnDisabled
								? t("dashboard.objects.maxObjectsTooltip", { max: MAX_NUMBER_OF_OBJECTS })
								: ""
						}
					>
						<Button
							type="primary"
							icon={<PlusOutlined />}
							disabled={isAddObjectBtnDisabled}
							onClick={() => setShowAddModal(true)}
						>
							{t("dashboard.objects.addObject")}
						</Button>
					</Tooltip>
				</AddBtnWrapper>
			)}

			{objects.length ? (
				<Row gutter={[24, 24]}>
					{objects.map((obj, index) => (
						<Col key={obj.finance_object_id} xs={24} md={12} xl={8}>
							<ObjectCard
								object={obj}
								index={index}
								isEditable={isEditable}
								onEdit={onEdit}
								onDelete={setObjectToDelete}
								currency={currency}
							/>
						</Col>
					))}
				</Row>
			) : (
				<>
					<Alert
						message={t("dashboard.overview.errors.complete_section_alert")}
						type="info"
						showIcon
					/>
					<NoObjectsCard
						onClick={() => setShowAddModal(true)}
						translations={{
							title: t("dashboard.objects.noObjects1"),
							subtitle: t("dashboard.objects.noObjects2"),
							addObject: t("dashboard.objects.addObject"),
						}}
					/>
				</>
			)}

			<Modal
				title={t("dashboard.objects.editObject")}
				open={!!objectToEdit}
				onCancel={() => setObjectToEdit(undefined)}
				cancelText={t("common.button_label.cancel")}
				okText={t("common.button_label.save")}
				okButtonProps={{
					htmlType: "submit",
					form: OBJECT_FORM_EDIT,
					disabled: isModalSendingData,
					loading: isModalSendingData,
				}}
				cancelButtonProps={{ disabled: isModalSendingData }}
				width={650}
				maskClosable={false}
				destroyOnClose
			>
				<Form
					form={formEdit}
					id={OBJECT_FORM_EDIT}
					layout="vertical"
					onFinish={editObject}
					disabled={isModalSendingData}
					preserve={false}
					initialValues={{ merchant_country: defaultCountry }}
				>
					<ObjectForm countries={countries} />
				</Form>
			</Modal>

			<Modal
				title={t("dashboard.objects.addObject")}
				open={showAddModal}
				onCancel={() => setShowAddModal(false)}
				cancelText={t("common.button_label.cancel")}
				okText={t("common.button_label.save")}
				okButtonProps={{
					htmlType: "submit",
					form: OBJECT_FORM_ADD,
					disabled: isModalSendingData,
					loading: isModalSendingData,
				}}
				cancelButtonProps={{
					disabled: isModalSendingData,
				}}
				width={650}
				maskClosable={false}
				destroyOnClose
			>
				<Form
					form={formAdd}
					id={OBJECT_FORM_ADD}
					layout="vertical"
					onFinish={addObject}
					preserve={false}
					disabled={isModalSendingData}
					initialValues={{ merchant_country: defaultCountry }}
				>
					<ObjectForm countries={countries} />
				</Form>
			</Modal>

			<Modal
				open={!!objectToDelete}
				okText={t("common.button_label.delete")}
				okButtonProps={{ danger: true }}
				onOk={deleteObject}
				onCancel={() => setObjectToDelete(undefined)}
				destroyOnClose
				closable={false}
				width={400}
			>
				<Space size={16} align="start">
					<ExclamationCircleOutlinedIcon />
					<div>
						<Typography.Title level={5}>
							{t("dashboard.objects.delete_modal_title")}
						</Typography.Title>
						<Typography.Text>
							<Trans i18nKey="dashboard.objects.deleteObjectModalTxt">
								{{ idx: objectToDelete && getObjectIndex(objectToDelete) + 1 }}
							</Trans>
						</Typography.Text>
					</div>
				</Space>
			</Modal>
		</>
	);
};

export default ObjectsTab;
