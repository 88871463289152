import { PartnerState } from "../reducers/partnerReducer";

export enum PartnerActions {
	SET_PARTNER_PARAMETERS = "SET_PARTNER_PARAMETERS",
}

export type Action = { type: PartnerActions.SET_PARTNER_PARAMETERS; parameters?: PartnerState };

export const setPartner = (parameters?: PartnerState): Action => {
	return {
		type: PartnerActions.SET_PARTNER_PARAMETERS,
		parameters,
	};
};
