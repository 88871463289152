import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { EuroCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Space, Spin, Typography } from "antd";
import styled from "styled-components";

import { ApplicationStatuses, User, UserApplication } from "@teylor-tools/Api";

import { RoutePaths } from "src/Routes";
import Error from "src/components/Error";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import ApplicationListItem from "src/pages/dashboard/components/ApplicationListItem";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const { Title, Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
`;

export const EmptyView = styled.div`
	background-color: #ffffff;
	padding: 40px 112px 80px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media (max-width: 992px) {
		padding: 32px 72px;
	}

	@media (max-width: 576px) {
		padding: 16px 24px;
	}
`;

const EuroIcon = styled(EuroCircleOutlined)`
	font-size: 24px;
	color: ${({ theme }) => theme.colorPrimary};
`;

const PageTitle = styled(Title)`
	font-size: 16px !important;
	margin: 0 !important;
`;

const RequiredActionCard = styled(Card)`
	margin-bottom: 25px;

	.ant-card-head-title {
		color: ${({ theme }) => theme.colorError};
	}
`;

const ApplicationList = ({ applications }: { applications: UserApplication[] }) => (
	<>
		{applications.map((application, index) => {
			if (!application.applicationId) return null;
			return (
				<Fragment key={application.applicationId}>
					<ApplicationListItem application={application} />
					{index < applications.length - 1 && <Divider />}
				</Fragment>
			);
		})}
	</>
);

const statusesRequiringAction = [ApplicationStatuses.Open, ApplicationStatuses.Signature];

const DashboardApplicationsList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<ErrorResponse>();
	const [applications, setApplications] = useState<UserApplication[]>();
	const { userCreateApplication } = useSelector((state: RootState) => state.configState);

	usePageTitle(t("common.page_title.application_list"));

	const completedApplications = applications?.filter(
		(application) => application.status && !statusesRequiringAction.includes(application.status)
	);
	const actionRequiredApplications = applications?.filter(
		(application) => application.status && statusesRequiringAction.includes(application.status)
	);

	useEffect(() => {
		Axios.get<User.ApplicationsList.RequestQuery, User.ApplicationsList.ResponseBody>(
			"user/applications"
		)
			.then(
				({ data }) => {
					setApplications(data.applications);
				},
				(err: ErrorResponse) => {
					setError(err);
					void Axios.error(err, t("dashboard.general.errors.dashboard_applications_list_error"));
				}
			)
			.finally(() => setLoading(false));
	}, [t]);

	if (loading)
		return (
			<SpinWrapper>
				<Spin />
			</SpinWrapper>
		);

	if (error) return <Error error={error} />;

	if (!applications?.length)
		return (
			<ContentWrapper>
				<HeaderWrapper>
					<PageTitle>{t("dashboard.general.my_loans")}</PageTitle>
				</HeaderWrapper>
				<EmptyView>
					<Space direction="vertical" size={24} align="center">
						<Space direction="vertical" align="center">
							<EuroIcon />
							<Title level={5}>{t("dashboard.general.no_loans_title")}</Title>
							<Text type="secondary">{t("dashboard.general.no_loans_subtitle")}</Text>
						</Space>

						{userCreateApplication && (
							<Button
								data-cy="new-application-button"
								type="primary"
								size="large"
								icon={<PlusOutlined />}
								onClick={() => {
									navigate(RoutePaths.applicationLoanInfo);
								}}
							>
								{t("dashboard.general.new_loan")}
							</Button>
						)}
					</Space>
				</EmptyView>
			</ContentWrapper>
		);

	return (
		<ContentWrapper>
			<HeaderWrapper>
				<PageTitle>{t("dashboard.general.my_loans")}</PageTitle>
				{userCreateApplication && (
					<Button
						type="primary"
						ghost
						icon={<PlusOutlined />}
						onClick={() => {
							navigate(RoutePaths.applicationLoanInfo);
						}}
						data-cy="new-application-button"
					>
						{t("dashboard.general.new_loan")}
					</Button>
				)}
			</HeaderWrapper>

			{!!actionRequiredApplications?.length && (
				<RequiredActionCard title={t("dashboard.general.action_required")}>
					<ApplicationList applications={actionRequiredApplications} />
				</RequiredActionCard>
			)}

			{!!completedApplications?.length && (
				<Card>
					<ApplicationList applications={completedApplications} />
				</Card>
			)}
		</ContentWrapper>
	);
};

export default DashboardApplicationsList;
