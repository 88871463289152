import { Outlet } from "react-router-dom";

import { Content, ContentWrapperNarrow, Layout } from "src/components/layout/LayoutComponents";
import PageFooter from "src/components/layout/PageFooter";
import PageHeader from "src/components/layout/page-header/PageHeader";

const AuthenticationPageLayout = () => {
	return (
		<Layout>
			<PageHeader transparentBg showUserArea={false} />
			<Content>
				<ContentWrapperNarrow>
					<Outlet />
				</ContentWrapperNarrow>
			</Content>
			<PageFooter />
		</Layout>
	);
};

export default AuthenticationPageLayout;
