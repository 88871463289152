import { useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/store/reducers/rootReducer";

export const usePageTitle = (pageTitle: string) => {
	const { display_name } = useSelector((state: RootState) => state.partnerState);

	useEffect(() => {
		document.title = display_name ? `${pageTitle} - ${display_name}` : pageTitle;
	}, [pageTitle, display_name]);
};
