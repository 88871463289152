import { AxiosResponse } from "axios";
import i18next from "i18next";

import { User, UserLogin } from "@teylor-tools/Api";

import store from "src/store/Store";
import { login, logout, setProfile, setProfileLoading } from "src/store/actions/userActions";
import { Axios } from "src/utils/Axios";

export const loginUser = (
	email: string,
	password: string
): Promise<void | AxiosResponse<never, never>> => {
	return Axios.post<UserLogin, never>("/user/account/login", {
		email,
		password,
	})
		.then(() =>
			getProfile().then(() => {
				store.dispatch(login());
			})
		)
		.catch((err) => Promise.reject(err));
};

export const getProfile = (): Promise<void | AxiosResponse<never, never>> => {
	store.dispatch(setProfileLoading(true));

	return Axios.get<User.ProfileList.RequestQuery, User.ProfileList.ResponseBody>("/user/profile")
		.then(
			({ data }) => {
				store.dispatch(
					setProfile({
						userId: data.user_id,
						email: data.email,
						emailVerified: data.emailVerified,
						interfaceLanguage: data.interface_language,
						communicationLanguage: data.communication_language,
						region: data.region,
					})
				);
				void i18next.changeLanguage(data.interface_language);
			},
			() => {
				store.dispatch(logout());
			}
		)
		.finally(() => store.dispatch(setProfileLoading(false)));
};
