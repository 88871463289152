import { useTranslation } from "react-i18next";

import { CheckOutlined as AntdCheckOutlined } from "@ant-design/icons/lib/icons";
import { default as AntdTitle } from "antd/es/typography/Title";
import styled from "styled-components";

const CheckOutlined = styled(AntdCheckOutlined)`
	color: ${({ theme }) => theme.colorPrimary};
	margin-right: 8px;
`;

const List = styled.ul`
	color: var(--teylor-text-secondary);
	padding-left: 50px;
`;

const Title = styled(AntdTitle)`
	margin: 10px 0;
`;

const CompanyRepresentativeInfo = () => {
	const { t } = useTranslation();

	return (
		<>
			<Title level={5}>{t("dashboard.company_representatives.empty_title")}</Title>

			<Title level={5}>
				<CheckOutlined />
				{t("dashboard.company_representatives.empty_section_1_title")}
			</Title>
			<List>
				<li>{t("dashboard.company_representatives.empty_section_1_point_1")}</li>
				<li>{t("dashboard.company_representatives.empty_section_1_point_2")}</li>
			</List>

			<Title level={5}>
				<CheckOutlined />
				{t("dashboard.company_representatives.empty_section_2_title")}
			</Title>
			<List>
				<li>{t("dashboard.company_representatives.empty_section_2_point_1")}</li>
			</List>

			<Title level={5}>
				<CheckOutlined />
				{t("dashboard.company_representatives.empty_section_3_title")}
			</Title>
			<List>
				<li>{t("dashboard.company_representatives.empty_section_3_point_1")}</li>
				<li>{t("dashboard.company_representatives.empty_section_3_point_2")}</li>
			</List>
		</>
	);
};

export default CompanyRepresentativeInfo;
