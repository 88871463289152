import { useTranslation } from "react-i18next";

import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Card as AntdCard, Button } from "antd";
import { default as AntdTitle } from "antd/es/typography/Title";
import styled, { css } from "styled-components";

import { maxWidth, mdScreen } from "src/breakpoints";
import Alert from "src/components/Alert";
import CompanyRepresentativeInfo from "src/pages/dashboard/components/CompanyRepresentativeInfo";

const CardStyles = css`
	padding-left: 92px;
	padding-right: 92px;

	& .ant-card-body {
		padding: 0;
	}

	@media (max-width: ${maxWidth(mdScreen)}) {
		padding: 30px;
	}
`;

const TopCard = styled(AntdCard)`
	${CardStyles};
	margin-top: 24px;
	text-align: center;
	padding-top: 68px;
	padding-bottom: 40px;
`;

const BottomCard = styled(AntdCard)`
	${CardStyles};
	border-top: 0;
	padding-top: 40px;
	padding-bottom: 96px;
`;

const UserIcon = styled(UserOutlined)`
	font-size: 19px;
	color: ${({ theme }) => theme.colorPrimary};
`;

const ButtonWrapper = styled.div`
	margin-top: 24px;
`;

const Title = styled(AntdTitle)`
	margin: 10px 0;
`;

const CompanyRepresentativeEmpty = ({ addRepresentative }: { addRepresentative: () => void }) => {
	const { t } = useTranslation();

	return (
		<>
			<Alert message={t("dashboard.overview.errors.complete_section_alert")} type="info" showIcon />

			<TopCard>
				<UserIcon />
				<Title level={5}>{t("dashboard.company_representatives.no_representatives_title")}</Title>
				<ButtonWrapper>
					<Button
						type="primary"
						size="large"
						onClick={addRepresentative}
						icon={<PlusOutlined />}
						data-cy="add-representative-button"
					>
						{t("dashboard.company_representatives.new_representative")}
					</Button>
				</ButtonWrapper>
			</TopCard>

			<BottomCard>
				<CompanyRepresentativeInfo />
			</BottomCard>
		</>
	);
};

export default CompanyRepresentativeEmpty;
