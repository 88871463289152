import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	DownloadOutlined,
	FilePdfOutlined,
	UploadOutlined,
} from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Row, Space, Typography, message } from "antd";
import { RuleObject } from "rc-field-form/lib/interface";
import styled from "styled-components";

import {
	Document,
	DocumentType,
	DocumentUrlResponse,
	DocumentsResponse,
	RelatedEntityKind,
	SignatoryKycStatus,
	SignatoryResponse,
	User,
	UserApplication,
} from "@teylor-tools/Api";
import TinFormItem, { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";
import { validateValueDifferentThan } from "@ui/form/validateValueDifferentThan";

import Alert from "src/components/Alert";
import Error from "src/components/Error";
import LoanDetails from "src/pages/dashboard/components/LoanDetails";
import SignatureTabIdentStep from "src/pages/dashboard/components/SignatureTab/SignatureTabIdentStep";
import { Status, getStatus } from "src/pages/dashboard/utils/status";
import { Axios, ErrorResponse } from "src/utils/Axios";

const { Text, Title, Link } = Typography;

export const CheckCircleIcon = styled(CheckCircleFilled)`
	color: ${({ theme }) => theme.colorSuccess};
	font-size: 21px;
	margin-right: 8px;
`;

const TermsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const CheckCircleOutlinedIcon = styled(CheckCircleOutlined)`
	color: ${({ theme }) => theme.colorSuccess};
	font-size: 56px;
	margin-top: 8px;
	margin-bottom: 24px;
`;

const SuccessCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	margin: 40px 0;
`;

const FilePdfIcon = styled(FilePdfOutlined)`
	color: ${({ theme }) => theme.colorPrimary};
`;

const GuarantorAgreementFileWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, auto);
	align-items: center;
	gap: 8px;
`;

const InputUpload = styled.input`
	display: none;
`;

const CloseCircleOutlinedIcon = styled(CloseCircleOutlined)`
	color: ${({ theme }) => theme.colorPrimary};
	margin-right: 4px;
`;

enum Step {
	TIN,
	IDENT,
	GUARANTOR_AGREEMENT,
	TC,
}

interface UpdateFileStatusParams {
	documentId?: string;
	isSuccessful: boolean;
}

interface Props {
	application: UserApplication;
	signatory: SignatoryResponse;
	updateRepresentatives: () => void;
}

const SignatureTab = ({ application, signatory, updateRepresentatives }: Props) => {
	const { t } = useTranslation();
	const [tinForm] = Form.useForm();
	const [submitForm] = Form.useForm();
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const guarantorAgreementUploadRef = useRef<HTMLInputElement>(null);
	const [guarantorAgreementFile, setGuarantorAgreementFile] = useState<Document | undefined>();

	const kycStatus = signatory.kyc_status || {};

	const status = getStatus(application.status);

	const isSigned =
		(kycStatus &&
			(Object.keys(kycStatus) as Array<keyof SignatoryKycStatus>).every((key) =>
				kycStatus[key]?.is_required ? kycStatus[key]?.is_completed : true
			)) ||
		false;

	const completedTinStep = kycStatus.tax_id_specified?.is_completed;
	const completedIdentStep = kycStatus.ident_completed?.is_completed;
	const completedGuarantorAgreementStep = kycStatus.guarantor_contract_uploaded?.is_completed;

	const showTinStep = !isSigned && kycStatus.tax_id_specified?.is_required;
	const showIdentStep = kycStatus.ident_completed?.is_required;
	const showGuarantorAgreementStep = kycStatus?.guarantor_contract_uploaded?.is_required;

	const stepsToDisplay = [
		...(showTinStep ? [Step.TIN] : []),
		...(showIdentStep ? [Step.IDENT] : []),
		...(showGuarantorAgreementStep ? [Step.GUARANTOR_AGREEMENT] : []),
		...(!isSigned &&
		kycStatus.legal_signature_accepted?.is_required &&
		kycStatus.sepa_mandate_accepted?.is_required
			? [Step.TC]
			: []),
	];

	const termsDisabled = [
		...(showTinStep ? [completedTinStep] : []),
		...(showIdentStep ? [completedIdentStep] : []),
		...(showGuarantorAgreementStep ? [completedGuarantorAgreementStep] : []),
	].some((stepCompletion) => !stepCompletion);

	const alertMsg =
		status === Status.NOT_COMPLETED
			? t("dashboard.signature.signatures_alert")
			: status === Status.IN_REVIEW_AFTER_SIGNATURE
			? t("dashboard.general.in_review_alert")
			: "";

	const handleUpdateTin = ({ taxid }: { taxid: string }) => {
		if (taxid && signatory.signatory_id) {
			Axios.patch<
				User.SignatoriesPartialUpdate.RequestBody,
				User.SignatoriesPartialUpdate.ResponseBody
			>(`/user/signatories/${signatory.signatory_id}`, {
				...signatory,
				taxid: parseTin(taxid),
			}).then(
				() => {
					void message.success(t("dashboard.signature.tin_was_updated"));
					updateRepresentatives();
				},
				(err: ErrorResponse) => Axios.error(err, t("dashboard.general.errors.error_try_again"))
			);
		}
	};

	const getUploadInfo = async (file: File) => {
		if (!application.applicationId) return;

		const uploadResponse = await Axios.post<
			User.ApplicationsDocumentsCreate.RequestBody,
			User.ApplicationsDocumentsCreate.ResponseBody
		>(`/user/applications/${application.applicationId}/documents`, {
			file_name: file.name,
			document_type: DocumentType.GuarantorContractSigned,
			related_entity_kind: RelatedEntityKind.Signatory,
			related_entity_id: signatory.signatory_id,
		});

		return uploadResponse?.data;
	};

	const uploadFile = async (file: File, url?: string) => {
		if (!url) return;

		await Axios.put(url, file, {
			headers: {
				"Content-Type": file.type,
				"x-ms-blob-type": "BlockBlob",
			},
		});
	};

	const updateFileStatus = async ({ documentId, isSuccessful }: UpdateFileStatusParams) => {
		if (!documentId) return;

		await Axios.patch(
			`/user/applications/${application.applicationId}/documents/${documentId}/upload_success`,
			{
				is_upload_successful: isSuccessful,
			}
		);
	};

	const getDocuments = async () => {
		const params: User.ApplicationsDocumentsDetail.RequestQuery = {
			document_type: DocumentType.GuarantorContractSigned,
			related_entity_kind: RelatedEntityKind.Signatory,
			related_entity_id: signatory.signatory_id,
		};

		// TODO: missing types
		return Axios.get<unknown, DocumentsResponse>(
			`/user/applications/${application.applicationId}/documents`,
			{ params }
		).then(
			({ data }) => setGuarantorAgreementFile(data.result[0]),
			(err: ErrorResponse) =>
				Axios.error(err, t("dashboard.signature.errors.error_getting_guarantor_agreement"))
		);
	};

	const handleUploadGuarantorAgreement = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;

		setLoadingUpload(true);

		const file = e.target.files[0];

		try {
			const uploadInfo = await getUploadInfo(file);
			const { document_id, upload_url } = uploadInfo || {};

			try {
				await uploadFile(file, upload_url);
				await updateFileStatus({
					documentId: document_id,
					isSuccessful: true,
				});
				await getDocuments();
				void message.success(t("dashboard.documents.file_upload_success"));
			} catch (err) {
				await updateFileStatus({
					documentId: document_id,
					isSuccessful: false,
				});
				// TODO: as ErrorResponse masks potential error if error comes not from Axios
				void Axios.error(err as ErrorResponse, t("dashboard.documents.errors.file_upload_fail"));
			}
		} catch (err) {
			// TODO: as ErrorResponse masks potential error if error comes not from Axios
			void Axios.error(err as ErrorResponse, t("dashboard.documents.errors.file_upload_fail"));
		} finally {
			setLoadingUpload(false);
			void updateRepresentatives();
		}

		if (guarantorAgreementUploadRef?.current?.value) {
			guarantorAgreementUploadRef.current.value = "";
		}
	};

	const handleDownload = () => {
		if (!application.applicationId || !guarantorAgreementFile?.document_id) return;
		// TODO: missing types
		Axios.get<never, DocumentUrlResponse>(
			`/user/applications/${application.applicationId}/documents/${guarantorAgreementFile.document_id}/download_url`
		)
			.then(({ data }) => window.open(data.download_url))
			.catch((err) =>
				Axios.error(err as ErrorResponse, t("dashboard.general.errors.error_try_again"))
			);
	};

	const handleDelete = async () => {
		if (!application.applicationId || !guarantorAgreementFile?.document_id) return;
		try {
			await Axios.delete(
				`/user/applications/${application.applicationId}/documents/${guarantorAgreementFile.document_id}`
			);
			await getDocuments();
			void message.success(t("dashboard.documents.file_delete_success"));
			void updateRepresentatives();
		} catch (err) {
			// TODO: as ErrorResponse masks potential error if error comes not from Axios
			void Axios.error(err as ErrorResponse, t("dashboard.documents.errors.file_delete_fail"));
		}
	};

	const handleSubmitApplication = () => {
		setLoadingSubmit(true);
		Axios.patch<
			User.ApplicationsSignatoriesRequirementsPartialUpdate.RequestBody,
			User.ApplicationsSignatoriesRequirementsPartialUpdate.ResponseBody
		>(
			`/user/applications/${application.applicationId}/signatories/${signatory.signatory_id}/requirements`,
			{
				signatory_id: signatory.signatory_id,
				application_id: application.applicationId,
				sepa_mandate_accepted: true,
				legal_signature_accepted: true,
			}
		)
			.catch((err: ErrorResponse) =>
				Axios.error(err, t("dashboard.general.errors.something_went_wrong"))
			)
			.finally(() => {
				setLoadingSubmit(false);
				void updateRepresentatives();
			});
	};

	useEffect(() => {
		void getDocuments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!signatory.requirements) return <Error />;

	return (
		<>
			{alertMsg && (
				<Col span={24}>
					<Alert type="info" message={alertMsg} showIcon style={{ marginBottom: 24 }} />
				</Col>
			)}

			<Row gutter={[40, 40]}>
				<Col span={24}>
					<LoanDetails application={application} />
				</Col>

				{isSigned && (
					<Col span={24}>
						<Card>
							<SuccessCardWrapper>
								<CheckCircleOutlinedIcon />
								<Title level={3}>{t("dashboard.signature.signatures_complete_title")}</Title>
								<Text type="secondary">
									{t("dashboard.signature.signatures_complete_subtitle")}
								</Text>
							</SuccessCardWrapper>
						</Card>
					</Col>
				)}

				{stepsToDisplay.map((step, index) => {
					switch (step) {
						case Step.TIN:
							return (
								<Col span={24} key={index}>
									<Card
										title={
											<Row align="middle">
												{completedTinStep && <CheckCircleIcon />}
												<Text>
													{`${index + 1}. `} {t("dashboard.signature.signatures_step_tin_title")}
												</Text>
											</Row>
										}
									>
										<Row>
											<Col span={9}>
												<Form form={tinForm} layout="vertical" onFinish={handleUpdateTin}>
													<TinFormItem
														type="personal"
														name="taxid"
														label={t("dashboard.signature.signatures_tin_label")}
														inputProps={{
															placeholder: t(
																"application_process.company_details.tax_number_placeholder"
															),
														}}
														initialValue={signatory.taxid}
														rules={[
															{
																validator: (rule: RuleObject, value: string) =>
																	validateValueDifferentThan({
																		value: parseTin(value),
																		differentThan: application.taxIdNumber,
																		error: t(
																			"dashboard.errors.personal_tin_must_be_different_than_company_tin"
																		),
																	}),
															},
														]}
													/>
													<Button type={completedTinStep ? "default" : "primary"} htmlType="submit">
														{signatory.taxid
															? t("common.button_label.update")
															: t("common.button_label.save")}
													</Button>
												</Form>
											</Col>
										</Row>
									</Card>
								</Col>
							);
						case Step.IDENT:
							return (
								<SignatureTabIdentStep
									completedIdentStep={completedIdentStep}
									isLoanSignatureRequired={kycStatus.loan_signature?.is_required}
									isSigned={isSigned}
									stepIndex={index}
									signatory={signatory}
									checkSteps={() => updateRepresentatives()}
									key={index}
								/>
							);
						case Step.GUARANTOR_AGREEMENT:
							return (
								<Col span={24} key={index}>
									<Card
										title={
											<Row align="middle">
												{completedGuarantorAgreementStep && <CheckCircleIcon />}
												<Text>
													{!isSigned && `${index + 1}. `}
													{t("dashboard.signature.signatures_step_guarantor_title")}
												</Text>
											</Row>
										}
									>
										{completedGuarantorAgreementStep ? (
											<>
												<Alert
													type="info"
													message={t("dashboard.signature.signatures_step_guarantor_alert")}
													showIcon
													style={{
														marginBottom: 24,
														border: "none",
													}}
												/>

												{guarantorAgreementFile ? (
													<Row justify="space-between">
														<GuarantorAgreementFileWrapper>
															<FilePdfIcon />
															{guarantorAgreementFile.document_name}
															<Button
																type="text"
																size="small"
																onClick={handleDownload}
																icon={<DownloadOutlined />}
															/>
															{!isSigned && (
																<Button
																	type="text"
																	size="small"
																	onClick={() => {
																		void handleDelete();
																	}}
																	icon={<CloseOutlined />}
																/>
															)}
														</GuarantorAgreementFileWrapper>
													</Row>
												) : (
													<div style={{ display: "flex", alignItems: "center" }}>
														<CloseCircleOutlinedIcon />
														<Text type="danger">
															{t("dashboard.signature.errors.error_getting_guarantor_agreement")}
														</Text>
													</div>
												)}
											</>
										) : (
											<Space direction="vertical" size={24}>
												<Text
													style={{
														whiteSpace: "pre-line",
													}}
												>
													{t("dashboard.signature.signatures_step_guarantor_content_1")}
													{t("dashboard.signature.signatures_step_guarantor_content_2")}
													<Link href={signatory.requirements?.guarantor_contract_url} download>
														{t("dashboard.signature.signatures_step_guarantor_content_3")}
													</Link>
													{t("dashboard.signature.signatures_step_guarantor_content_4")}
													{t("dashboard.signature.signatures_step_guarantor_content_5")}
												</Text>
												<label htmlFor="guarantor-agreement-upload">
													<InputUpload
														ref={guarantorAgreementUploadRef}
														id="guarantor-agreement-upload"
														type="file"
														onChange={(evt) => {
															void handleUploadGuarantorAgreement(evt);
														}}
													/>
													<Button
														type="primary"
														icon={<UploadOutlined />}
														onClick={() => guarantorAgreementUploadRef?.current?.click()}
														loading={loadingUpload}
													>
														{t("dashboard.signature.upload_signed_agreement")}
													</Button>
												</label>
											</Space>
										)}
									</Card>
								</Col>
							);
						case Step.TC:
							return (
								<Col span={24} key={index}>
									<Card
										title={
											<Row align="middle">
												<Text>
													{`${index + 1}. `}
													{t("dashboard.signature.signatures_step_terms_title")}
												</Text>
											</Row>
										}
									>
										<Alert
											type="info"
											showIcon
											message={t(
												"dashboard.overview.pending_items.signatures_complete_previous_steps"
											)}
											style={{ marginBottom: 16 }}
										/>
										<Form form={submitForm} layout="vertical" onFinish={handleSubmitApplication}>
											<TermsWrapper>
												<Form.Item
													name="terms1"
													valuePropName="checked"
													rules={[
														{
															validator: (_, value) =>
																value
																	? Promise.resolve()
																	: Promise.reject(
																			t(
																				"application_process.account_creation.errors.terms_and_conditions_invalid"
																			)
																	  ),
														},
													]}
												>
													<Checkbox disabled={termsDisabled}>
														<Text>{t("dashboard.signature.signatures_step_terms_1")}</Text>
													</Checkbox>
												</Form.Item>
												<Form.Item
													name="terms2"
													valuePropName="checked"
													rules={[
														{
															validator: (_, value) =>
																value
																	? Promise.resolve()
																	: Promise.reject(
																			t(
																				"application_process.account_creation.errors.terms_and_conditions_invalid"
																			)
																	  ),
														},
													]}
												>
													<Checkbox disabled={termsDisabled}>
														<Text>{t("dashboard.signature.signatures_step_terms_2")}</Text>
													</Checkbox>
												</Form.Item>
												<Button
													type="primary"
													size="large"
													style={{
														alignSelf: "center",
													}}
													htmlType="submit"
													loading={loadingSubmit}
													disabled={termsDisabled}
												>
													{t("dashboard.signature.submit_signature")}
												</Button>
											</TermsWrapper>
										</Form>
									</Card>
								</Col>
							);
					}
				})}
			</Row>
		</>
	);
};

export default SignatureTab;
