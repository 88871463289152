import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

import { Tag as AntdTag, Button, Typography } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";

import { ApplicationStatuses, UserApplication } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { yearMonthDay } from "@teylor-tools/utils/dateFormats";

import { RoutePaths } from "src/Routes";

import { TAG_COLORS, getStatus } from "../utils/status";

const { Text } = Typography;

const ApplicationDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ApplicationDetailsTop = styled.div`
	display: flex;
	align-items: center;
`;

const AplicationDetailsBottom = styled.div`
	.ant-typography {
		color: rgba(0, 0, 0, 0.55);
	}
`;

const Tag = styled(AntdTag)`
	border-radius: 50px;
`;

const Amount = styled(Text)`
	font-size: 24px;
	font-weight: 600;
	margin-right: 16px;
`;

const Date = styled(Text)`
	font-weight: 600;
	margin-right: 16px;
`;

const ActionButton = ({ status, id }: { status: ApplicationStatuses; id: string }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const btnText =
		status === ApplicationStatuses.Open
			? t("application_process.offer.complete_application")
			: t("dashboard.general.view");

	return (
		<Button
			type="primary"
			ghost={![ApplicationStatuses.Open, ApplicationStatuses.Signature].includes(status)}
			onClick={() => navigate(generatePath(RoutePaths.dashboardApplicationOverview, { id }))}
			data-cy="complete-application-button"
		>
			{btnText}
		</Button>
	);
};

const ApplicationListItem = ({ application }: { application: UserApplication }) => {
	const { t } = useTranslation();
	const { currency } = useFormatter();

	const { applicationId, shortApplicationId, status, created, loanSize } = application;

	if (!applicationId || !shortApplicationId || !status || !created || !loanSize) {
		return null;
	}

	const applicationStatus = getStatus(status);

	return (
		<ApplicationDetails>
			<div>
				<ApplicationDetailsTop>
					<Amount>
						{currency(loanSize, { showFractions: false, currency: application.currency })}
					</Amount>
					<Tag color={TAG_COLORS[applicationStatus]}>
						{t(`common.application_info.application_status.${applicationStatus}`)}
					</Tag>
				</ApplicationDetailsTop>
				<AplicationDetailsBottom>
					<Date>{dayjs(created).utc().format(yearMonthDay)}</Date>{" "}
					<Text>ID: {shortApplicationId}</Text>
				</AplicationDetailsBottom>
			</div>
			<ActionButton status={status} id={applicationId} />
		</ApplicationDetails>
	);
};

export default ApplicationListItem;
