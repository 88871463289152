import { useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReloadOutlined } from "@ant-design/icons";
import { Tag as AntdTag, Button, Card, Col, Form, Row, Spin, Typography, message } from "antd";
import styled from "styled-components";

import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";

import Alert from "src/components/Alert";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const { Text } = Typography;

const Tag = styled(AntdTag)`
	border-radius: 50px;
`;

interface PasswordChangeParams {
	oldPassword: string;
	newPassword: string;
}

const DashboardSettings = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [loadingResendVerificationEmail, setLoadingResendVerificationEmail] = useState(false);
	const [form] = Form.useForm();
	const partnerState = useSelector((state: RootState) => state.partnerState);
	const { emailVerified, email, isProfileLoading } = useSelector(
		(state: RootState) => state.userState
	);

	usePageTitle(t("common.page_title.settings"));

	const resendVerificationEmail = () => {
		setLoadingResendVerificationEmail(true);
		Axios.post(`/user/account/resend_conf_email`, {})
			.then(
				() => void message.success(t("dashboard.general.verification_email_send")),
				(error: ErrorResponse) => {
					void Axios.error(error, t("dashboard.general.errors.something_went_wrong"));
				}
			)
			.finally(() => setLoadingResendVerificationEmail(false));
	};

	const onFinish = ({ oldPassword, newPassword }: PasswordChangeParams) => {
		setLoading(true);

		Axios.post(`/user/account/change_password`, {
			old_password: oldPassword,
			new_password: newPassword,
		})
			.then(() => {
				form.resetFields();
				void message.success(t("settings.account.password_change_success"));
			})
			.catch((error: ErrorResponse) => Axios.error(error))
			.finally(() => {
				setLoading(false);
			});
	};

	const getEmailCardContent = () => {
		if (isProfileLoading) return <Spin />;

		if (!email)
			return <Alert message={t("dashboard.general.errors.something_went_wrong")} type="error" />;

		return (
			<>
				<Text>{email}</Text>
				{emailVerified ? (
					<Tag color="green" style={{ marginLeft: 8 }}>
						{t("settings.account.verified")}
					</Tag>
				) : (
					<Button
						type="link"
						icon={<ReloadOutlined />}
						onClick={resendVerificationEmail}
						loading={loadingResendVerificationEmail}
					>
						{t("settings.account.resendVerificationEmail")}
					</Button>
				)}
			</>
		);
	};

	return (
		<ContentWrapper>
			<Row gutter={[24, 24]}>
				<Col span={24}>
					<Card title={t("settings.account.email")}>{getEmailCardContent()}</Card>
				</Col>
				<Col span={24}>
					<Card title={t("application_process.account_creation.password")}>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<PasswordFormItem
								name="oldPassword"
								label={t("settings.account.password_old")}
								checkPattern={false}
							/>
							<PasswordFormItem name="newPassword" label={t("settings.account.password_new")} />
							<PasswordFormItem
								name="repeatPassword"
								label={t("settings.account.password_repeat")}
								rules={[
									{
										required: true,
										message: t("application_process.error_messages.field_required"),
									},
									{
										validator: (_, repeatPassword) =>
											repeatPassword === form.getFieldValue("newPassword")
												? Promise.resolve()
												: Promise.reject(
														t("application_process.account_creation.errors.password_repeat_invalid")
												  ),
									},
								]}
							/>
							<Button
								type="primary"
								size="large"
								htmlType="submit"
								loading={loading}
								data-cy="change-password-button"
							>
								{t("settings.account.change_password")}
							</Button>
						</Form>
					</Card>
				</Col>
				<Col span={24}>
					<Card title={t("settings.account.delete_account")}>
						<Text type="secondary">
							<Trans
								i18nKey="settings.account.delete_account_subtitle"
								values={{
									phone: partnerState.customer_support_phone,
									email: partnerState.customer_support_email,
								}}
							/>
						</Text>
					</Card>
				</Col>
			</Row>
		</ContentWrapper>
	);
};

export default DashboardSettings;
