import {
	AllBanksLoanPurposes,
	EntitlementType,
	PartnerConfig,
	PartnerConfigParameters,
} from "@teylor-tools/Api";

import { Action, PartnerActions } from "../actions/partnerActions";

export interface PartnerState extends PartnerConfigParameters {
	configLoaded: boolean;
	terms_url: PartnerConfig["terms_url"];
	data_protection_url: PartnerConfig["data_protection_url"];
	is_partner_terms_required: boolean;
	userApplicationTypes: AllBanksLoanPurposes[];
	entitlements: EntitlementType[];
}

const defaultState: PartnerState = {
	configLoaded: false,
	customer_support_email: "",
	customer_support_phone: "",
	display_name: "",
	legal_name: "",
	terms_url: "",
	data_protection_url: "",
	is_partner_terms_required: false,
	userApplicationTypes: [],
	entitlements: [],
};

const partnerReducer = function (state = defaultState, action: Action): PartnerState {
	switch (action.type) {
		case PartnerActions.SET_PARTNER_PARAMETERS:
			return {
				...state,
				...action.parameters,
				configLoaded: true,
			};
	}

	return state;
};

export default partnerReducer;
