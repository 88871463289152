import { CompanyTypes, CountryCode, Currency, Feature } from "@teylor-tools/Api";
import { CountriesList, getCountries } from "@teylor-tools/utils/getCountries";

import { ConfigAction, ConfigActions } from "../actions/configActions";

export interface ConfigState {
	currencies?: string[];
	defaultCurrency?: Currency;
	urlList?: Feature;
	languages?: string[];
	defaultLanguage?: string;
	companyName?: {
		legalName?: string;
		displayName?: string;
	};
	companyTypes?: CompanyTypes[];
	defaultCompanyType?: CompanyTypes;
	countries: CountriesList[];
	userCreateApplication?: boolean;
	preferredCountries?: CountryCode[];
}

const defaultState: ConfigState = {
	currencies: undefined,
	defaultCurrency: undefined,
	urlList: undefined,
	languages: undefined,
	defaultLanguage: undefined,
	companyName: {
		legalName: undefined,
		displayName: undefined,
	},
	companyTypes: [],
	defaultCompanyType: undefined,
	countries: [],
	userCreateApplication: true,
	preferredCountries: undefined,
};

const configReducer = function (state = defaultState, action: ConfigAction): ConfigState {
	switch (action.type) {
		case ConfigActions.SET_CONFIG:
			return {
				...state,
				currencies: action.payload.currencies?.value.supported,
				defaultCurrency: action.payload.currencies?.value.default as Currency,
				urlList: action.payload.urlList,
				languages: action.payload.languages?.value.supported,
				defaultLanguage: action.payload.languages?.value.default,
				companyName: {
					legalName: action.payload.companyName?.value.legal_name,
					displayName: action.payload.companyName?.value.display_name,
				},
				companyTypes: action.payload.companyTypes?.value.supported as CompanyTypes[] | undefined,
				defaultCompanyType: action.payload.companyTypes?.value.default as CompanyTypes | undefined,
				userCreateApplication: action.payload.userCreatesApplication?.value.enabled,
				preferredCountries: action.payload.preferredCountries?.value.preferred as
					| CountryCode[]
					| undefined,
			};
		case ConfigActions.SET_COUNTRY_LIST:
			return {
				...state,
				countries: getCountries({
					preferredCountries: action.payload?.preferredCountries,
					countries: action.payload.countries,
				}),
			};
		default:
			return { ...state };
	}
};

export default configReducer;
