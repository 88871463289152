import React from "react";
import { LinkProps } from "react-router-dom";
import { useHref, useLinkClickHandler } from "react-router-dom";

import { Typography } from "antd";
import { LinkProps as AntdLinkProps } from "antd/es/typography/Link";

const AntdLink = Typography.Link;

interface LinkRef extends AntdLinkProps {
	to: LinkProps["to"];
}

const Link: React.FC<LinkRef> = ({ to, children, ...rest }) => {
	const href = useHref(to);
	const handleClick = useLinkClickHandler(to);

	return (
		<>
			<AntdLink
				{...rest}
				href={href}
				onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
					handleClick(event);
				}}
			>
				{children}
			</AntdLink>
		</>
	);
};

export default Link;
