import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";

import Link from "@ui/Link";
import EmailFormItem from "@ui/form/form-items/email-form-item/EmailFormItem";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";

import { LocationState, RoutePaths } from "src/Routes";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";
import { loginUser } from "src/utils/api/authentication";

import { Button } from "./style/AuthenticationComponents";

const { Title, Text } = Typography;

interface LoginCredentials {
	email: string;
	password: string;
}

const Login = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [form] = Form.useForm();
	const { userCreateApplication } = useSelector((state: RootState) => state.configState);

	usePageTitle(t("common.button_label.login"));

	const onFinish = ({ email, password }: LoginCredentials) => {
		setLoading(true);
		loginUser(email, password)
			.then(() => {
				if (searchParams.get("application") === "true") {
					navigate(RoutePaths.applicationResult);
				} else {
					navigate(
						(location.state as LocationState)?.urlToRedirect || RoutePaths.dashboardApplications
					);
				}
			})
			.catch((err: ErrorResponse) => {
				void Axios.error(err, t("authentication.errors.login_error"));
			})
			.finally(() => setLoading(false));
	};

	return (
		<>
			<ContentCard>
				<Space direction="vertical" size={24}>
					<Title level={3}>{t("common.button_label.login")}</Title>
					<Form form={form} layout="vertical" onFinish={onFinish}>
						<EmailFormItem
							rules={[
								{
									required: true,
									message: t("application_process.error_messages.field_required"),
								},
							]}
						/>
						<PasswordFormItem
							name="password"
							label={t("application_process.account_creation.password")}
							checkPattern={false}
						/>
						<Row>
							<Col span={24} style={{ textAlign: "center" }}>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									loading={loading}
									data-cy="signin-button"
								>
									{t("common.button_label.login")}
								</Button>
							</Col>
						</Row>
					</Form>
				</Space>
				<Text style={{ textAlign: "center", marginTop: 16 }}>
					{t("authentication.forgot_password.forgot_password_link_1")}{" "}
					<Link to={RoutePaths.forgotPassword} data-cy="forgot-password-link">
						{t("authentication.forgot_password.forgot_password_link_2")}
					</Link>
				</Text>
			</ContentCard>
			{userCreateApplication && (
				<Space
					direction="vertical"
					size={4}
					align="center"
					style={{ width: "100%", marginTop: 40 }}
				>
					<Text style={{ textAlign: "center" }}>
						{t("authentication.register.new_application_link_1")}
					</Text>
					<Link to={RoutePaths.applicationLoanInfo} data-cy="create-account-link">
						{t("authentication.register.new_application_link_2")}
					</Link>
				</Space>
			)}
		</>
	);
};

export default Login;
