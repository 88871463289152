import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";

import { User } from "@teylor-tools/Api";
import PasswordFormItem from "@ui/form/form-items/password-form-item/PasswordFormItem";

import { RoutePaths } from "src/Routes";
import TermsAndConditionsCheckboxes from "src/components/form/TermsAndConditionsCheckboxes";
import { ContentCard } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import { Axios, ErrorResponse } from "src/utils/Axios";

import { Button, CheckCircleIcon } from "./style/AuthenticationComponents";

const { Title, Text } = Typography;

interface RegistrationInput {
	password: string;
	terms: boolean;
	termsPartner: boolean;
}

const RegisterInvitation = () => {
	const { t, i18n } = useTranslation();
	const [registerSuccess, setRegisterSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { token } = useParams() as { token: string };
	const [form] = Form.useForm();

	usePageTitle(t("common.page_title.register"));

	const onFinish = ({ password, terms, termsPartner }: RegistrationInput) => {
		setLoading(true);
		Axios.post<
			User.AccountInviteAcceptCreate.RequestBody,
			User.AccountInviteAcceptCreate.ResponseBody
		>("/user/account/invite_accept", {
			password,
			token,
			accepted_terms: terms,
			accepted_partner_terms: termsPartner,
			interface_language: i18n.language,
			communication_language: i18n.language,
		})
			.then(() => setRegisterSuccess(true))
			.catch((error: ErrorResponse) => Axios.error(error))
			.finally(() => setLoading(false));
	};

	if (registerSuccess) {
		return (
			<ContentCard>
				<div style={{ textAlign: "center" }}>
					<CheckCircleIcon />
				</div>
				<Title level={3} style={{ textAlign: "center" }}>
					{t("authentication.register.registration_success_title")}
				</Title>
				<Text type="secondary" style={{ textAlign: "center" }}>
					{t("authentication.register.registration_success_subtitle")}
				</Text>
				<Button
					type="primary"
					size="large"
					style={{ marginTop: 48 }}
					onClick={() => navigate(RoutePaths.login)}
				>
					{t("authentication.login.go_to_login")}
				</Button>
			</ContentCard>
		);
	}

	return (
		<ContentCard>
			<Space direction="vertical" size={24}>
				<div>
					<Title level={3}>{t("authentication.register.set_password")}</Title>
					<Text type="secondary">{t("authentication.register.set_password_subtitle")}</Text>
				</div>
				<Form form={form} layout="vertical" onFinish={onFinish}>
					<PasswordFormItem
						name="password"
						label={t("application_process.account_creation.password")}
					/>
					<PasswordFormItem
						name="passwordRepeat"
						label={t("application_process.account_creation.password_repeat")}
						rules={[
							{ required: true, message: t("application_process.error_messages.field_required") },
							{
								validator: (_, passwordRepeat) =>
									passwordRepeat === form.getFieldValue("password")
										? Promise.resolve()
										: Promise.reject(
												t("application_process.account_creation.errors.password_repeat_invalid")
										  ),
							},
						]}
					/>
					<TermsAndConditionsCheckboxes />
					<Row>
						<Col span={24} style={{ textAlign: "center" }}>
							<Button type="primary" size="large" htmlType="submit" loading={loading}>
								{t("authentication.register.set_password")}
							</Button>
						</Col>
					</Row>
				</Form>
			</Space>
		</ContentCard>
	);
};

export default RegisterInvitation;
