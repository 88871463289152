import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, message } from "antd";
import Text from "antd/es/typography/Text";
import styled from "styled-components";

import {
	ApplicationStatuses,
	Signatory,
	SignatoryWithId,
	UserApplication,
} from "@teylor-tools/Api";
import RepresentativeModal from "@ui/representative-modal/RepresentativeModal";

import { mdScreen, minWidth } from "src/breakpoints";
import Alert from "src/components/Alert";
import CompanyRepresentative from "src/pages/dashboard/components/CompanyRepresentative";
import CompanyRepresentativeEmpty from "src/pages/dashboard/components/CompanyRepresentativeEmpty";
import CompanyRepresentativeInfo from "src/pages/dashboard/components/CompanyRepresentativeInfo";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const RepresentativesWrapper = styled.div`
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(auto-fill, minmax(456px, auto));
`;

const ButtonWrapper = styled(Col)`
	@media (min-width: ${minWidth(mdScreen)}) {
		text-align: right;
	}
`;

interface Props {
	representatives: SignatoryWithId[];
	application: UserApplication;
	updateRepresentatives: () => void;
	getPendingItems: () => void;
	ownershipPercentageExceeded: boolean;
	noSoleSignatory: boolean;
}

const CompanyRepresentativesList = ({
	representatives,
	application,
	updateRepresentatives,
	getPendingItems,
	ownershipPercentageExceeded,
	noSoleSignatory,
}: Props) => {
	const [isRepresentativeModalVisible, setIsRepresentativeModalVisible] = useState<boolean>(false);
	const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);
	const { countries } = useSelector((state: RootState) => state.configState);

	const { t } = useTranslation();

	const addRepresentative = (signatory: Signatory) => {
		Axios.post(`/user/applications/${application.applicationId}/signatories`, {
			data: [signatory],
		}).then(
			() => {
				void message.success(t("dashboard.company_representatives.representative_added"));
				void updateRepresentatives();
				getPendingItems();
			},
			(err: ErrorResponse) => {
				void Axios.error(err, t("dashboard.general.errors.error_try_again"));
			}
		);
	};

	const openAddModal = () => {
		setIsRepresentativeModalVisible(true);
	};

	const closeModal = () => {
		setIsRepresentativeModalVisible(false);
	};

	return (
		<>
			{isRepresentativeModalVisible && (
				<RepresentativeModal
					save={addRepresentative}
					close={closeModal}
					countries={countries}
					companyTin={application.taxIdNumber}
				/>
			)}

			{representatives.length ? (
				<>
					{noSoleSignatory && (
						<Alert
							type="error"
							message={t(
								"dashboard.company_representatives.errors.err_application_signatories_sole_or_atleast_2"
							)}
							showIcon
							style={{ marginBottom: 24 }}
						/>
					)}

					{ownershipPercentageExceeded && (
						<Alert
							type="error"
							message={t(
								"dashboard.company_representatives.errors.err_share_percentage_greater_then_100"
							)}
							showIcon
							style={{ marginBottom: 24 }}
						/>
					)}
					{application.status === ApplicationStatuses.Open && (
						<div style={{ marginBottom: 24 }}>
							<Row align="middle" gutter={[0, 16]}>
								<Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
									<Text type="secondary">
										<QuestionCircleOutlined
											style={{
												marginRight: 4,
											}}
										/>
										{t("dashboard.company_representatives.not_sure_who_add")}{" "}
										<Button
											type="link"
											onClick={() => setIsInfoModalVisible(true)}
											style={{
												padding: 0,
											}}
										>
											{t("dashboard.company_representatives.not_sure_who_add_link_text")}
										</Button>
									</Text>
								</Col>
								<ButtonWrapper xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
									<Button
										type="primary"
										icon={<PlusOutlined />}
										onClick={openAddModal}
										data-cy="add-another-representative"
									>
										{t("dashboard.overview.pending_items.add_representatives")}
									</Button>
								</ButtonWrapper>
							</Row>
						</div>
					)}
					<RepresentativesWrapper>
						{representatives.map((rep) => (
							<CompanyRepresentative
								key={rep.signatory_id}
								representative={rep}
								onDeleteOrUpdate={() => {
									updateRepresentatives();
									getPendingItems();
								}}
								onUpdateTin={() => updateRepresentatives()}
								applicationStatus={application.status}
								companyTin={application.taxIdNumber}
							/>
						))}
					</RepresentativesWrapper>
				</>
			) : (
				<CompanyRepresentativeEmpty addRepresentative={openAddModal} />
			)}

			<Modal
				open={isInfoModalVisible}
				onCancel={() => setIsInfoModalVisible(false)}
				onOk={() => setIsInfoModalVisible(false)}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
				width={633}
			>
				<CompanyRepresentativeInfo />
			</Modal>
		</>
	);
};

export default CompanyRepresentativesList;
