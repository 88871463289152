import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Col, Form, Row, Space, Typography } from "antd";
import { useTheme } from "styled-components";

import CompanySelectFormItem, {
	SelectedCompany,
} from "@ui/form/form-items/company-select-form-item/CompanySelectFormItem";

import { RoutePaths } from "src/Routes";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import ApplicationFormBackButton from "src/pages/application-form/components/ApplicationFormBackButton";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import { useApplicationForm } from "src/providers/application-form-provider/useApplicationForm";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios } from "src/utils/Axios";

const { Title } = Typography;

const ApplicationCompanyName = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const { defaultCompanyType, countries } = useSelector((state: RootState) => state.configState);
	const defaultCountry = useMemo(() => countries[0]?.code, [countries]);

	const {
		applicationForm: { companyName },
		setApplicationForm,
		setIsSelectedCompany,
	} = useApplicationForm();

	usePageTitle(t("common.page_title.new_application"));

	const [form] = Form.useForm<{ companyName: string }>();

	const onFinish = ({ companyName }: { companyName: string }) => {
		setApplicationForm((prevState) => ({ ...prevState, companyName }));
		navigate(RoutePaths.applicationCompanyDetails);
	};

	const setCompanyData = (company: SelectedCompany, isSelected: boolean) => {
		const {
			street,
			houseNumber,
			companyPostCode,
			companyLocation,
			companyCountry,
			companyCreditProviderId,
		} = company;

		setApplicationForm((prevState) => ({
			...prevState,
			companyType: defaultCompanyType,
			street,
			houseNumber,
			companyPostCode,
			companyLocation,
			companyCountry,
			companyCreditProviderId,
		}));

		setIsSelectedCompany(isSelected);
	};

	return (
		<>
			<ApplicationSteps stepIndex={1} />
			<ContentWrapper>
				<ApplicationFormCard>
					<ApplicationFormBackButton path={RoutePaths.applicationLoanInfo} />
					<Space direction="vertical" size={24}>
						<Title level={3}>{t("application_process.company_name.company_name_title")}</Title>
						<Form form={form} layout="vertical" onFinish={onFinish}>
							<CompanySelectFormItem
								initialValue={companyName}
								setCompanyData={setCompanyData}
								defaultCountry={defaultCountry}
								axios={Axios.axios}
								linkColour={theme.colorLink}
							/>
							<Row>
								<Col
									span={24}
									style={{
										textAlign: "center",
									}}
								>
									<ApplicationFormButton
										type="primary"
										size="large"
										htmlType="submit"
										data-cy="next-button"
									>
										{t("common.button_label.next")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Form>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationCompanyName;
