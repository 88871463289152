import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Col, Row, Spin, Typography } from "antd";
import styled, { css } from "styled-components";

import { ApplicationStatuses, CompanyTypes, User, UserApplication } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useIndicativeRate } from "@teylor-tools/hooks/useIndicativeRates";
import { parseTin } from "@ui/form/form-items/tin-form-item/TinFormItem";

import { RoutePaths } from "src/Routes";
import { ContentWrapper } from "src/components/layout/LayoutComponents";
import { usePageTitle } from "src/hooks/usePageTitle";
import ApplicationFormButton from "src/pages/application-form/components/ApplicationFormButton";
import ApplicationFormCard from "src/pages/application-form/components/ApplicationFormCard";
import ApplicationSteps from "src/pages/application-form/components/ApplicationSteps";
import {
	ApplicationFormState,
	emptyApplication,
	useApplicationForm,
} from "src/providers/application-form-provider/useApplicationForm";
import { RootState } from "src/store/reducers/rootReducer";
import { Axios, ErrorResponse } from "src/utils/Axios";

const { Title, Text } = Typography;

const SpinWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;

const Space = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;
	text-align: center;
`;

const Box = styled.div`
	border: solid 1px ${({ theme }) => theme.colorPrimary};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;
	height: 100%;
	white-space: pre-line;
`;

const BoxValue = styled(Text)`
	font-size: 18px !important;
	margin-top: 12px;
`;

const ContactBox = styled.div`
	border: solid 1px ${({ theme }) => theme.colorPrimary};
	padding: 32px;
`;

const ContactItem = styled.div`
	display: flex;
	justify-content: center;
	gap: 8px;
	align-items: center;
`;

const IconStyle = css`
	font-size: 18px;
	color: ${({ theme }) => theme.colorPrimary};
`;

const MailIcon = styled(MailOutlined)`
	${IconStyle}
`;

const PhoneIcon = styled(PhoneOutlined)`
	${IconStyle}
`;

const MANDATORY_FIELDS = [
	"loanSize",
	"loanDuration",
	"companyName",
	"companyType",
	"companyPostCode",
	"hrNumber",
	"businessPurpose",
	"allBanksLoanPurpose",
	"purposeDescription",
];

const ApplicationResult = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [offer, setOffer] = useState<UserApplication>();
	const navigate = useNavigate();
	const { applicationForm, setApplicationForm } = useApplicationForm();
	const partnerState = useSelector((state: RootState) => state.partnerState);
	const { defaultCurrency } = useSelector((state: RootState) => state.configState);
	const { currency } = useFormatter();
	const { indicativeMonthlyRate, indicativeInterestRate } = useIndicativeRate();

	usePageTitle(t("common.page_title.new_application"));

	const isFormValid = Object.keys(applicationForm)
		.filter((key) => MANDATORY_FIELDS.includes(key))
		.every((key) => applicationForm[key as keyof ApplicationFormState]);

	useEffect(() => {
		// If user has accessed this page with no application form data,
		// redirect him to the settings page.
		if (!isFormValid) {
			navigate(RoutePaths.dashboardSettings);
			return;
		}

		const postData: User.ApplicationsCreate.RequestBody = {
			...applicationForm,
			companyType: applicationForm.companyType as CompanyTypes,
			currency: defaultCurrency,
			taxIdNumber: parseTin(applicationForm.taxIdNumber),
		};

		// don't add referrer if it matches the website as long as it wasn't set from a querystring before.
		if (!postData.referrer && !document.referrer.includes(window.location.host)) {
			postData.referrer = document.referrer;
		}

		Axios.post<User.ApplicationsCreate.RequestBody, User.ApplicationsCreate.ResponseBody>(
			"/user/applications",
			postData
		)
			.then(
				(response) => {
					setOffer(response.data.application);
				},
				(error: ErrorResponse) => {
					void Axios.error(error);
				}
			)
			.finally(() => {
				setLoading(false);
				// reset application form after response is received
				setApplicationForm(emptyApplication);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading)
		return (
			<>
				<ApplicationSteps stepIndex={4} />
				<SpinWrapper>
					<Spin size="large" />
				</SpinWrapper>
			</>
		);

	if (!offer || offer.status === ApplicationStatuses.Rejected)
		return (
			<>
				<ApplicationSteps stepIndex={4} />
				<ContentWrapper>
					<ApplicationFormCard>
						<Space>
							<Title level={3} data-cy="rejected-offer-title">
								{t("application_process.offer.offer_rejected_title")}
							</Title>
							<Text>{t("application_process.offer.offer_rejected_text_1")}</Text>
							{partnerState.customer_support_email && partnerState.customer_support_phone && (
								<>
									<Text strong>{t("application_process.offer.offer_rejected_text_2")}</Text>
									<ContactBox>
										<ContactItem
											style={{
												marginBottom: 16,
											}}
										>
											<MailIcon />
											<Text strong>{partnerState.customer_support_email}</Text>
										</ContactItem>
										<ContactItem>
											<PhoneIcon />
											<Text strong>{partnerState.customer_support_phone}</Text>
										</ContactItem>
									</ContactBox>
								</>
							)}
							<Row>
								<Col span={24}>
									<ApplicationFormButton
										type="ghost"
										size="large"
										onClick={() => navigate(RoutePaths.index)}
										data-cy="go-back-to-website"
									>
										{t("application_process.offer.offer_rejected_return")}
									</ApplicationFormButton>
								</Col>
							</Row>
						</Space>
					</ApplicationFormCard>
				</ContentWrapper>
			</>
		);

	return (
		<>
			<ApplicationSteps stepIndex={4} />
			<ContentWrapper>
				<ApplicationFormCard>
					<Space>
						<Title level={3}>{t("application_process.offer.offer_success_title")}</Title>
						<Row gutter={[16, 16]}>
							<Col sm={6} xs={24}>
								<Box>
									<Text type="secondary">{t("application_process.offer.offer_loan_amount")}</Text>
									<BoxValue strong>
										{currency(offer.loanSize, { showFractions: false, currency: offer.currency })}
									</BoxValue>
								</Box>
							</Col>
							<Col sm={6} xs={24}>
								<Box>
									<Text type="secondary">{t("application_process.offer.offer_loan_duration")}</Text>
									<BoxValue strong>
										{offer.loanDuration} {t("common.units.months")}
									</BoxValue>
								</Box>
							</Col>
							<Col sm={6} xs={24}>
								<Box>
									<Text type="secondary">{t("application_process.offer.offer_monthly_rate")}</Text>
									<BoxValue strong>
										{indicativeMonthlyRate(offer?.pricing, offer?.currency, false) || "-"}
									</BoxValue>
								</Box>
							</Col>
							<Col sm={6} xs={24}>
								<Box>
									<Text type="secondary">
										{t("application_process.offer.offer_indicative_interest_rate")}
									</Text>
									<BoxValue strong>{indicativeInterestRate(offer?.pricing) || "-"}</BoxValue>
								</Box>
							</Col>
						</Row>
						<Text type="secondary">
							{t("application_process.offer.offer_success_offer_id")} {offer.shortApplicationId}
						</Text>
						<Text>{t("application_process.offer.offer_success_text_1")}</Text>
						<Text type="secondary">{t("application_process.offer.offer_success_text_2")}</Text>

						<Row>
							<Col span={24}>
								<ApplicationFormButton
									type="primary"
									size="large"
									onClick={() =>
										offer.applicationId &&
										navigate(
											generatePath(RoutePaths.dashboardApplicationOverview, {
												id: offer.applicationId,
											})
										)
									}
									data-cy="complete-application"
								>
									{t("application_process.offer.complete_application")}
								</ApplicationFormButton>
							</Col>
						</Row>
					</Space>
				</ApplicationFormCard>
			</ContentWrapper>
		</>
	);
};

export default ApplicationResult;
