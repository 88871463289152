import { useTranslation } from "react-i18next";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Tooltip, Typography } from "antd";
import styled from "styled-components";

import { UserApplication } from "@teylor-tools/Api";
import { useFormatter } from "@teylor-tools/hooks/formatter";
import { useIndicativeRate } from "@teylor-tools/hooks/useIndicativeRates";
import { isValidNumericValue } from "@teylor-tools/utils/numbers";

const { Text } = Typography;

const LoanDetailsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const LoanDetailsBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const InfoIcon = styled(InfoCircleOutlined)`
	margin-left: 4px;
`;

interface Props {
	application: UserApplication;
}

const LoanDetails = ({ application }: Props) => {
	const { t } = useTranslation();
	const { currency, decimalToPercent } = useFormatter();
	const { indicativeMonthlyRate } = useIndicativeRate();
	const { loanSize, loanDuration, pricing } = application;

	const isIndicative =
		!application.pricing.is_final_interest_rate && !application.pricing.is_final_transaction_fee;

	return (
		<Card title={t("dashboard.overview.loan_details.loan_details")}>
			<LoanDetailsWrapper>
				<LoanDetailsBox>
					<Text strong>
						{currency(loanSize, { showFractions: false, currency: application.currency })}
					</Text>
					<Text type="secondary">{t("application_process.loan_information.loan_amount")}</Text>
				</LoanDetailsBox>

				<LoanDetailsBox>
					<Text strong>
						{loanDuration} {t("common.units.months")}
					</Text>
					<Text type="secondary">{t("dashboard.signature.term")}</Text>
				</LoanDetailsBox>

				{isIndicative ? (
					<LoanDetailsBox>
						<Text strong>
							{indicativeMonthlyRate(application.pricing, application.currency) || "-"}
						</Text>
						<Text type="secondary">
							{t("dashboard.overview.loan_details.indicative_monthly_rate")}
							<Tooltip title={t("dashboard.overview.loan_details.indicative_monthly_rate_tooltip")}>
								<InfoIcon />
							</Tooltip>
						</Text>
					</LoanDetailsBox>
				) : (
					<LoanDetailsBox>
						<Text strong>
							{pricing.monthly_payment
								? currency(pricing.monthly_payment, { currency: application.currency })
								: "-"}
						</Text>
						<Text type="secondary">{t("dashboard.overview.loan_details.monthly_rate")}</Text>
					</LoanDetailsBox>
				)}

				{!isIndicative && (
					<>
						<LoanDetailsBox>
							<Text strong>
								{isValidNumericValue(pricing.total_interest_rate)
									? decimalToPercent(pricing.total_interest_rate, undefined, true)
									: "-"}
							</Text>
							<Text type="secondary">{t("dashboard.overview.loan_details.interest_rate")}</Text>
						</LoanDetailsBox>

						<LoanDetailsBox>
							<Text strong>
								{isValidNumericValue(pricing.total_transaction_fee)
									? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									  currency(pricing.total_transaction_fee!, { currency: application.currency })
									: "-"}
							</Text>
							<Text type="secondary">{t("dashboard.overview.loan_details.transaction_fee")}</Text>
						</LoanDetailsBox>
					</>
				)}
			</LoanDetailsWrapper>
		</Card>
	);
};

export default LoanDetails;
